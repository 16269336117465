import React, { useEffect, useState } from "react";

import { faColumns, faColumns3 } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { REQ_STATUS } from "../../../util/constants";
import { FH } from "../../main-styles";
import * as S from "../styles";
import List from "./List";
import View from "./View";
import Resizable from "react-resizable-layout";
import { Spinner } from "reactstrap";
import { USERS } from "../../../api/USERS";

// create agents context
export const TicketsContext = React.createContext(null);

function Tickets() {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const params = useParams();
  const [maxed, setMaxed] = useState(true);
  const [width, setWidth] = useState(300);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    page: query?.page || 0,
    perPage: query?.perPage || 10,
    needle: query?.needle || "",
    currentTicket: params?.id || "",
    agents: [],
    loadedAgents: false,
  });

  const { status, data, page, perPage, needle, currentTicket, agents } = state;

  useEffect(() => {
    init();
    loadAgents();
  }, []);

  async function loadAgents() {
    let agents = await USERS.get.agents();
    setState((s) => ({ ...s, agents, loadedAgents: true }));
  }

  useEffect(() => {
    rewritePath();
  }, [page, perPage, needle, currentTicket]);

  useEffect(() => {
    if (currentTicket) {
      navigate(`/agents/tickets/${currentTicket}`);
    } else {
      navigate(`/agents/tickets`);
    }
  }, [currentTicket]);

  function rewritePath() {
    // function responsible by rewriting the URL path
    const params = new URLSearchParams();
    params.set("page", page);
    params.set("perPage", perPage);
    params.set("needle", needle);
    params.set("t", currentTicket || "");
    window.history.pushState({}, "", `${window.location.pathname}?${params}`);
  }

  async function init() {
    // get width from local storage
    const width = localStorage.getItem("ticketListWidth");
    if (width) {
      setWidth(width);
    }
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
  }

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  function updateTicket(id, field, value) {
    console.log(
      `vai atualizar o ticket ${id} com o campo ${field} e valor ${value}`
    );
    console.log("DATA", data);
    const newData = data.map((ticket) => {
      console.log("PERCORRENDO TICKET", ticket);
      if (parseInt(ticket.id) === parseInt(id)) {
        return { ...ticket, [field]: value };
      }
      return ticket;
    });
    updateField("data", newData);
  }

  function selectTicket(ticket) {
    console.log("selectedTicket", ticket);
    updateField("currentTicket", ticket);
  }

  function saveWidth(width) {
    console.log("width", width.position);
    // save to local storage
    localStorage.setItem("ticketListWidth", width.position);
    setWidth(width.position);
  }

  function clearSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <TicketsContext.Provider
      value={{
        state,
        updateField,
        selectTicket,
        updateTicket,
      }}
    >
      <S.TicketsGrid maxed={maxed}>
        <Resizable
          axis={"x"}
          onResizeEnd={saveWidth}
          width={width}
          min={250}
          initial={width}
        >
          {({ position, separatorProps }) => (
            <>
              <S.TicketsList maxed={maxed} style={{ width: position }}>
                {/* <S.TicketListLeft>
            <S.TitleContainer>Inbox</S.TitleContainer>
            <div>
              Meus
              <br />
              Prioridade Alta
              <br />
              Todos
              <br />
              Não Atribuídos
              <br />
              Finalizados
              <br />
            </div>
          </S.TicketListLeft> */}
                {/* <div
        {...separatorProps}
        style={{
          ...separatorProps.style,
          left: position + separatorProps.style.left,
        }}
      /> */}

                <S.TicketListRight maxed={maxed}>
                  <S.TitleContainer bottom={true}>
                    <FH
                      style={{ width: "100%", justifyContent: "space-between" }}
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setMaxed(!maxed)}
                      >
                        <FontAwesomeIcon
                          icon={maxed ? faColumns3 : faColumns}
                        />
                      </div>
                      <span>Tickets</span>
                    </FH>
                  </S.TitleContainer>
                  <List />
                </S.TicketListRight>
              </S.TicketsList>
              <S.Separator
                {...separatorProps}
                style={{
                  ...separatorProps.style,
                }}
                onMouseDown={clearSelection}
                onMouseMove={clearSelection}
              />
              <S.TicketView maxed={maxed}>
                <View />
              </S.TicketView>
              <S.TicketDetails>
                <S.TitleContainer bottom={true}>Detalhes</S.TitleContainer>
              </S.TicketDetails>
            </>
          )}
        </Resizable>
      </S.TicketsGrid>
    </TicketsContext.Provider>
  );
}

export default Tickets;
