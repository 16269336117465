import api from "../axios";
import { store } from "../../redux/configureStore";
export const REPORTS = {
  get: {
    agents: {
      performance: async (startDate, endDate, tenantId) => {
        if (!tenantId) tenantId = store.getState().appReducer.app.tid;
        try {
          const response = await api.get("/reports/agents/performance", {
            params: {
              tenantId,
              startDate: startDate,
              endDate: endDate,
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching performance data:", error);
          throw error;
        }
      },
      csat: async (startDate, endDate, tenantId) => {
        if (!tenantId) tenantId = store.getState().appReducer.app.tid;
        try {
          const response = await api.get("/reports/csat", {
            params: {
              tenantId,
              startDate: startDate.toISOString(),
              endDate: endDate.toISOString(),
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching CSAT data:", error);
        }
      },
      tickets: async (startDate, endDate, tenantId) => {
        try {
          if (!tenantId) tenantId = store.getState().appReducer.app.tid;
          const response = await api.get("/reports/tickets", {
            params: {
              tenantId,
              startDate,
              endDate,
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching ticket data:", error);
        }
      },
    },
    groups: {
      performance: async (startDate, endDate, tenantId) => {
        try {
          if (!tenantId) tenantId = store.getState().appReducer.app.tid;
          const response = await api.get("/reports/groups/performance", {
            params: {
              tenantId,
              startDate,
              endDate,
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching group performance data:", error);
        }
      },
      tickets: async (startDate, endDate, tenantId) => {
        try {
          if (!tenantId) tenantId = store.getState().appReducer.app.tid;
          const response = await api.get("/reports/groups/tickets", {
            params: {
              tenantId,
              startDate,
              endDate,
            },
          });
          return response.data;
        } catch (error) {
          console.error("Error fetching group tickets data:", error);
        }
      }
    },
  },
};
