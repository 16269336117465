import { store } from "../../redux/configureStore";
import api from "../axios";

// gets tid from appReducer

export const TICKETS = {
  get: {
    mine: async (page, perPage) => {
      const tid = store.getState().appReducer.app.tid;
      const id = store.getState().authReducer.user.id;
      //"/users/:tid/:userId/tickets"
      const data = await api.get(
        `/tickets/users/${tid}/${id}/tickets?page=${page}&limit=${perPage}`
      );
      return data.data;
    },
    one: async (id) => {
      const tid = store.getState().appReducer.app.tid;
      const url = `/tickets/${tid}/${id}`;
      const data = await api.get(url);
      return data.data;
    },
    myCompany: async (page, perPage) => {
      return [];
    },
    CSAT: async (id) => {
      const tid = store.getState().appReducer.app.tid;
      const url = `/tickets/${tid}/${id}/csat`;
      const data = await api.get(url);
      return data.data;
    },
    search: async (needle, page, perPage) => {},
    agents: {
      all: async (page, perPage, showClosed=false,onlyMine=false) => {
        const tid = store.getState().appReducer.app.tid;
        const data = await api.get(
          `/tickets/${tid}?page=${page}&limit=${perPage}&showClosed=${showClosed}&onlyMine=${onlyMine}`
        );
        return data.data;
      },
    },
  },
  set: {
    new: async (subject, description, files, tid, id, tags = []) => {
      if (!tid) tid = store.getState().appReducer.app.tid;
      if (!id) id = store.getState().authReducer.user.id;
      const url = `/tickets`;
      const response = await api.post(url, {
        subject,
        description,
        files,
        user_id: id,
        tid,
        tags,
      });
      return response.data;
    },
    update: async (id, content, status, files = []) => {
      const tid = store.getState().appReducer.app.tid;
      const url = `/tickets/${tid}/${id}/reply`;
      // TODO: Handle files
      const response = await api.put(url, {
        content,
        status,
        files,
      });
      return response.data;
    },
    updateStatus: async (id, status) => {
      const tid = store.getState().appReducer.app.tid;
      const url = `/tickets/${tid}/${id}/status`;
      const response = await api.patch(url, {
        status,
      });
      return response.data;
    },
    sendCSAT: async (id, score, solved) => {
      const tid = store.getState().appReducer.app.tid;
      const response = await api.post(`/tickets/${tid}/${id}/csat`, {
        score,
        solved,
      });
      return response.data;
    },
    updateAgent: async (id, agent_id) => {
      const tid = store.getState().appReducer.app.tid;
      const response = await api.put(`/tickets/${tid}/${id}/agent`, {
        agent_id
      });
      return response.data;
    },
    delete: async (id) => {},
    activate: async (id) => {},
  },
};
