import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  font-size: 24px;
  padding: 10px;
  gap: 10px;
  margin: 0; /* Add this line to remove the margin */
  position: relative;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const Thumbnail = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #17e759;
`;

export const UserName = styled.span`
  font-size: 16px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 100;
  display: flex;
  min-width: 210px;
  flex-direction: column;
`;

export const MenuItem = styled.div`
  color: #333;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;
