import { faAngleDown, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

function GotoBottomButton({min=-50, max= 140}) {
  const { colors } = useSelector((state) => state.appReducer.app);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const isDown = offset > document.body.scrollHeight - window.innerHeight - 300;

  return (
    <>
      <Button
        className="animate__animated animate__bounce"
        onClick={isDown ? scrollToTop : scrollToBottom}
        background={colors?.main}
        color={colors?.headerText}
        top={offset > 200 ? min : max}
        // rotate 180 degree if isDown is true
       
      >
        <FontAwesomeIcon icon={faAngleDown} style={{ transition: 'all 0.3s',transform: isDown ? "rotate(180deg)" : "rotate(0deg)" }} />
      </Button>
    </>
  );
}

const Button = styled.div`
  position: fixed;
  margin-left: -60px;
  margin-top: ${(props) => props.top}px;
  font-size: 30px;
  background-color: ${(props) => props.background || "black"};
  color: ${(props) => props.color || "white"};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  // drop shadow
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  transition: all 0.3s;
`;

export default GotoBottomButton;
