import React, { useEffect, useState } from "react";

import * as S from "../styles";

import Or from "../../../components/Or";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import mainlogo from "../../../assets/helpdesk/logo-small.png";

import powered from "../../../assets/helpdesk/powered.png";
import { Logo, Link, Input, FH, Text, Title } from "../../main-styles";
import PoweredLink from "../../../components/PoweredLink";
import { REQ_STATUS } from "../../../util/constants";
import { useNavigate, useParams } from "react-router-dom";
import { USERS } from "../../../api/USERS";

function Validate() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { appReducer } = useSelector((state) => state);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
  });
  const { app } = appReducer;
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  const { status } = state;

  useEffect(() => {
    // set page title
    document.title = `${app.name} - Ativação de usuário`;
    init();
  }, []);

  async function init() {
    // verifica o codigo
    if (!uuid) return setState({ status: REQ_STATUS.ERROR });
    // verifica se o codigo é valido
    try {
      const res = await USERS.set.activate(uuid);

      setState({ status: REQ_STATUS.SUCCESS });
    } catch (error) {
      setState({ status: REQ_STATUS.ERROR });
    }
  }

  function gotoLogin() {
    navigate("/auth/sign-in");
  }

  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <hr />
        {status === REQ_STATUS.LOADING && <Text>Validando...Aguarde...</Text>}
        {status === REQ_STATUS.SUCCESS && <><Title>Validado com sucesso!</Title>
        <Text>Você já pode acessar</Text>
        <Button onClick={gotoLogin}>Acessar</Button>
        </>}
        {status === REQ_STATUS.ERROR && (
          <>
          <Text>Código incorreto ou usuário já foi validado anteriormente</Text>
          <Button onClick={gotoLogin}>Acessar</Button>
          </>
        )}
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default Validate;
