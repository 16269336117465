import React from "react";

import { faTicket, faCog, faLineChart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Menu, MenuItem } from "./styles";
import Tickets from "./Tickets";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";

function Agents() {
  const navigate = useNavigate();
  
  function goTo(path) {
    navigate(path);
  }

  return (
    <Container>
      <Menu>
        <MenuItem

          onClick={() => {
            goTo("/agents/tickets");
          }}
        >
          <FontAwesomeIcon icon={faTicket} />
        </MenuItem>
        <MenuItem

          onClick={() => {
            goTo("/agents/configuracoes");
          }}
        >
          <FontAwesomeIcon icon={faCog} />
        </MenuItem>
        <MenuItem

          onClick={() => {
            goTo("/agents/reports");
          }}
        >
          <FontAwesomeIcon icon={faLineChart} />
        </MenuItem>
      </Menu>
      <Outlet />
    </Container>
  );
}

export default Agents;
