import React from "react";

import Editor from "react-simple-wysiwyg";
import styled from "styled-components";

function WYSEditor({ value, disabled=false, onChange }) {
  return (
    <Editor
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      containerProps={{ style: { resize: "vertical", minHeight: 200 } }}
    />
  );
}

export default WYSEditor;
