import React, { useContext, useEffect, useState } from "react";

import * as S from "../../styles";
import {
  Button,
  Description,
  FH,
  Input,
  Label,
  NewStatusSelect,
  Subtitle,
  Text,
  Title,
} from "../../../main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  REQ_STATUS,
  TICKET_STATUS,
  TICKET_STATUS_COLORS,
  TICKET_STATUS_NAMES,
} from "../../../../util/constants";
import { TICKETS } from "../../../../api/TICKETS";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment-timezone";
import { UTILS } from "../../../../api/UTILS";
import { getTicketStatusAgent } from "../../../../util/functions";
import { Modal, Spinner } from "reactstrap";
import styled from "styled-components";

import createDOMPurify from "dompurify";
import {
  faBoxOpen,
  faCheck,
  faImage,
  faInfoCircle,
  faMessage,
  faPaperclip,
  faSparkles,
  faTimes,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import WYSEditor from "../../../../components/WYSEditor";
import CSAT from "../../../../components/CSAT";
import StatusSelect from "../../../../components/StatusSelect";
import { TicketsContext } from "..";
import AgentSelect from "../../../../components/AgentSelect";

const DOMPurify = createDOMPurify(window);

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { colors } = useSelector((state) => state.appReducer.app);
  const { user } = useSelector((state) => state.authReducer);
  const context = useContext(TicketsContext);
  const { updateTicket, state:contextstate } = context;
  const { agents, loadingAgents } = contextstate;
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    answer: "",
    files: [],
    noTicket: false,
    newStatus: TICKET_STATUS.WAITING_CUSTOMER,
    agents: [],
  });
  const { status, data, answer, files, newStatus, noTicket } = state;

  useEffect(() => {}, []);

  useEffect(() => {
    if (!id) {
      return setState((s) => ({ ...s, noTicket: true }));
    } else {
      setState((s) => ({ ...s, noTicket: false }));
    }
    load();
  }, [id]);

  async function load() {
    if (!id) return;
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    const data = await TICKETS.get.one(id);

    console.log("TICKETS", data);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE, data }));
  }

  function setAnswer(answer) {
    console.log("ANSWER", answer);
    setState((s) => ({ ...s, answer }));
  }

  function addFile(files) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({ ...s, files: [...s.files, ...files] }));
  }

  function removeFile(index) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({
      ...s,
      files: s.files.filter((_, i) => i !== index),
    }));
  }

  async function submit() {
    if (status !== REQ_STATUS.IDLE) return;
    if (!answer) {
      return toast.error("Digite uma resposta");
    }
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));

    // SE O STATUS DO TICKET FOR CREATED, DEVE MANTER CREATED
    // SE O STATUS DO TICKET FOR QUALQUER OUTRO, DEVE SER SEMPRE QUEUED
    console.log("STATUS ATUAL", data?.status);
    const newStatus =
      data?.status === TICKET_STATUS.CREATED
        ? TICKET_STATUS.CREATED
        : TICKET_STATUS.QUEUED;

    let files = await uploadImages();

    const newData = await TICKETS.set.update(id, answer, newStatus, files);
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        thread: [...s.data.thread, newData],
        status: newStatus,
      },
      files: [],
      answer: "",
    }));
  }

  function closeTicketConfirm() {
    confirmAlert({
      title: "Fechar o ticket",
      message: "Tem certeza que deseja marcar o ticket como fechado?",
      buttons: [
        {
          label: "Sim, fechar o ticket",
          onClick: closeTicket,
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function uploadImages() {
    // pega um signed url para upload por imagem
    const renamedFiles = files.map((file) => {
      // add a huge random string to the file name to avoid conflicts
      const randomString = moment().format("YYYYMMDDHHmmss");
      return new File([file], `${randomString}_${file.name}`, {
        type: file.type,
      });
    });
    let promises = await UTILS.get.singed_url(renamedFiles);
    console.log("PROMISES", promises);
    // faz o upload da imagem
    let uploads = await promises.map(async (item, i) => {
      let response = await UTILS.set.upload_file(
        item.signed_url,
        renamedFiles[i]
      );
      console.log("RESPONSE", response);
      return item.url;
    });
    uploads = await Promise.all(uploads);
    console.log("UPLOADS", uploads);
    return uploads;
  }

  async function closeTicket() {
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    const newStatus = TICKET_STATUS.CLOSED;
    const newData = await TICKETS.set.updateStatus(id, newStatus);
    toast.success("Ticket fechado com sucesso");
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        status: newStatus,
      },
    }));
  }

  function resolveTicketConfirm() {
    confirmAlert({
      title: "Resolver o ticket",
      message: "Tem certeza que deseja marcar o ticket como resolvido?",
      buttons: [
        {
          label: "Sim, marcar como resolvido",
          onClick: () => resolveTicket(),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function resolveTicket() {
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    const newStatus = TICKET_STATUS.RESOLVED;
    const newData = await TICKETS.set.updateStatus(id, newStatus);
    toast.success("Ticket resolvido com sucesso");
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        status: newStatus,
      },
    }));
  }

  function updateAgent(agent) {
    confirmAlert({
      title: "Atualizar o responsável do ticket",
      message: "Tem certeza que deseja atualizar o responsável do ticket?",
      buttons: [
        {
          label: "Sim, atualizar",
          onClick: () => updateTicketAgent(agent),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function updateTicketAgent(agent) {
    const newData = await TICKETS.set.updateAgent(id, agent);
    // console.log("newData", newData);
    // console.log("agent", agent);
    // console.log("id", id);
    updateTicket(id, "agent", agent);
    toast.success("Responsável atualizado com sucesso");
  }

  function updateStatusConfirm(status) {
    confirmAlert({
      title: "Atualizar o status do ticket",
      message: "Tem certeza que deseja atualizar o status do ticket?",
      buttons: [
        {
          label: "Sim, atualizar",
          onClick: () => updateTicketStatus(status),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function updateTicketStatus(status) {
    const newData = await TICKETS.set.updateStatus(id, status);
    console.log("newData", newData);
    console.log("status", status);
    console.log("id", id);
    updateTicket(id, "status", status);
    toast.success("Status atualizado com sucesso");
  }

  const colorinfo = getTicketStatusAgent(data?.status);

  const CAN_ANSWER = !(
    data.status === TICKET_STATUS.CLOSED ||
    data.status === TICKET_STATUS.RESOLVED
  );

  if (noTicket && status === REQ_STATUS.IDLE) {
    return (
      <S.Ticket
        title="Nenhum ticket"
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // full height
          height: "100%",
          color: "#afafaf",
          backgroundColor: "#ffffff",
        }}
      >
        <FontAwesomeIcon icon={faBoxOpen} style={{ fontSize: 50 }} />
        <Description style={{ color: "#afafaf" }}>Nenhum ticket</Description>
      </S.Ticket>
    );
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <S.Ticket title="Carregando" style={{ padding: 20 }}>
        <Spinner />
      </S.Ticket>
    );
  }

  return (
    <S.Ticket>
      <S.TitleContainer bottom={true} style={{ width: "100%" }}>
        <FH style={{ justifyContent: "space-between", width: "100%" }}>
          <Subtitle
            style={{ textWrap: "nowrap", flex: 3 }}
          >{`#${data?.id} - ${data.subject}`}</Subtitle>
          <FH
            style={{ justifyContent: "flex-end", gap: 5, alignItems: "center" }}
          >
            {/* <div style={{ fontSize: 12 }}>
              Prioridade:{" "}
              <NewStatusSelect
                value={data?.priority}
                onChange={(e) => console.log(e)}
              >
                <option value="low">Baixa</option>
                <option value="normal">Normal</option>
                <option value="high">Alta</option>
              </NewStatusSelect>
            </div> */}
            <div style={{ fontSize: 12 }}>
              Responsável:{" "}
              {loadingAgents ? "-": <AgentSelect
                value={data?.agent}
                options={agents?.map((agent) => ({
                  value: agent.id,
                  label: agent.name,
                  image: agent.thumbnail,
                  email: agent.email,
                }))}
                onChange={(e) => updateAgent(e.value)}
              />}
            </div>
            <div style={{ fontSize: 12 }}>
              Status:{" "}
              <StatusSelect
                value={data?.status}
                options={Object.keys(TICKET_STATUS_NAMES).map((key) => ({
                  value: key,
                  label: TICKET_STATUS_NAMES[key],
                  color: TICKET_STATUS_COLORS[key],
                }))}
                onChange={(e) => updateStatusConfirm(e.value)}
              />
            </div>
          </FH>
        </FH>
      </S.TitleContainer>

      <S.TicketsContainer>
        <Reply
          description={data?.description}
          files={data?.files}
          created_at={data?.created_at}
          username={data?.user}
          user_id={data?.user_id}
        />
        <hr />
        {data?.thread?.map((item) => {
          if (item.type === "status_update") {
            return (
              <StatusUpdate
                created_at={item.date}
                description={item.content}
                username={item.name}
                user_id={item.created_by}
              />
            );
          }

          return (
            <Reply
              key={item.id}
              description={item.content}
              files={item.files}
              created_at={item.date}
              username={item.name}
              thumbnail={item.thumbnail}
              user_id={item.user_id}
            />
          );
        })}
        {/* INICIO RESPOSTA */}
        {CAN_ANSWER ? (
          <>
            <ThreadItem>
              <FH style={{ justifyContent: "space-between" }}>
                <Label style={{ marginBottom: 10 }}>
                  <FontAwesomeIcon icon={faMessage} /> Responder
                </Label>
                {answer.length > 50 && (
                  <Label
                    className="animate__animated animate__tada"
                    style={{ marginBottom: 10, color: "purple" }}
                  >
                    <FontAwesomeIcon icon={faSparkles} /> Organizar as idéias
                  </Label>
                )}
              </FH>
              <WYSEditor
                value={answer}
                onChange={setAnswer}
                disabled={status === REQ_STATUS.SENDING}
              />
              <hr />
              <Description>
                <FontAwesomeIcon icon={faPaperclip} />
                Anexos (somente imagens)
              </Description>
              <label
                htmlFor="file-upload"
                style={{
                  cursor: "pointer",

                  border: "1px dashed #666666",
                  backgroundColor: "#f9f9f9",
                  borderRadius: 5,
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#666",
                  gap: 5,
                }}
              >
                <FontAwesomeIcon icon={faImage} />
                Clique para enviar uma imagem
              </label>
              <input
                id="file-upload"
                type="file"
                multiple={true}
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => addFile(e.target.files)}
                disabled={status === REQ_STATUS.SENDING}
              />
              {files.length > 0 && <hr />}
              {files.map((file, index) => (
                <FH key={index} style={{ justifyContent: "space-between" }}>
                  <Text>{file.name}</Text>
                  <div
                    style={{
                      flex: 1,
                      borderBottom: `1px dotted #ccc`,
                      height: `1rem`,
                      marginLeft: 10,
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                  />
                  <Text onClick={() => removeFile(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Text>
                </FH>
              ))}
              <hr />
              <FH>
                <div>
                  <Label>Novo Status</Label>
                  <StatusSelect
                    value={newStatus}
                    menuPlacement={"top"}
                    options={Object.keys(TICKET_STATUS_NAMES).map((key) => ({
                      value: key,
                      label: TICKET_STATUS_NAMES[key],
                      color: TICKET_STATUS_COLORS[key],
                    }))}
                    onChange={(e) => setState((s) => ({ ...s, newStatus: e }))}
                  />
                </div>
                <Button
                  backgroundColor={
                    status === REQ_STATUS.SENDING ? "#ccc" : colors.main
                  }
                  onClick={submit}
                  style={{ marginTop: 10 }}
                >
                  {status === REQ_STATUS.SENDING ? "Enviando" : "Enviar"}
                </Button>
              </FH>
            </ThreadItem>
          </>
        ) : (
          <>
            <ThreadItem style={{ opacity: 0.3 }}>
              <FH>
                <FontAwesomeIcon icon={faInfoCircle} /> Este ticket não pode ser
                respondido, pois está finalizado.
              </FH>
            </ThreadItem>
          </>
        )}
      </S.TicketsContainer>
    </S.Ticket>
  );
}

function Reply({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const mine = parseInt(user.id) === parseInt(user_id);
  const [selected, setSelected] = useState(null);
  return (
    <ThreadItem mine={mine}>
      <FH>
        <ThreadUserImg
          src={
            thumbnail
              ? thumbnail
              : "https://img.seidecor.com.br/fit-in/150x150/-LIG_iJcJTGJZwPKMJiZ/generated-images/generated_zmxo1.png"
          }
        />
        <div>
          <Description style={{ fontWeight: "bold" }}>
            {mine ? "Você" : username}
          </Description>
          <Description
            style={{ color: "#ababab", fontSize: "0.8rem" }}
            title={moment(created_at).format("DD/MM/YYYY HH:mm")}
          >
            {moment(created_at) < moment().add(-3, "days")
              ? moment(created_at).format("DD/MM/YYYY HH:mm")
              : moment(created_at).fromNow()}
          </Description>
        </div>
      </FH>
      <Text
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description),
        }}
      />
      {files && files.length > 0 && (
        <>
          <hr />
          <Description>
            <FontAwesomeIcon icon={faPaperclip} /> Anexos
          </Description>
          <FH style={{ gap: 25, flexWrap: "wrap" }}>
            {files.map((file, index) => {
              const filename = file.split("/").pop();
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    fontSize: "0.8rem",
                    color: "#ababab",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelected(file)}
                >
                  <img
                    src={file}
                    alt={filename}
                    style={{
                      width: 100,
                      height: 100,
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      cursor: "pointer",
                      // cover
                      objectFit: "cover",
                      // center
                      objectPosition: "center",
                      // elipsis text
                    }}
                  />
                  <span
                    style={{
                      maxWidth: 100,
                      // elipsis long text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexWrap: "nowrap",
                      textWrap: "nowrap",
                      fontSize: "0.7rem",
                    }}
                  >
                    {filename}
                  </span>
                </div>
              );
            })}
          </FH>
        </>
      )}
      <Modal
        isOpen={selected !== null}
        toggle={() => setSelected(null)}
        size="lg"
      >
        {selected && (
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={selected}
              alt="Anexo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <span
              style={{
                fontSize: "0.8rem",
                color: "#ababab",
              }}
            >
              {selected?.split("/").pop()}
            </span>
          </div>
        )}
      </Modal>
    </ThreadItem>
  );
}

function StatusUpdate({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const me = parseInt(user.id) === parseInt(user_id);
  return (
    <StatusUpdateContainer>
      <strong>{me ? "Você" : username}</strong> marcou o ticket como{" "}
      <strong>"{TICKET_STATUS_NAMES[description]}"</strong> em{" "}
      <strong>{moment(created_at).format("DD/MM/YYYY HH:mm")}</strong>
    </StatusUpdateContainer>
  );
}

const StatusUpdateContainer = styled.div`
  padding: 1rem;
  color: #ababab;
  font-size: 0.9rem;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const ThreadItem = styled.div`
  border: ${(props) =>
    props.mine ? "2px solid #e6e6e6" : "1px solid #cccccc"};
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  gap: 0.5rem;
  background-color: #fff;
`;

const ThreadUserImg = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 5px;
`;

export default View;
