import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  locked: false,
  lockedReason: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
      state.locked = false;
      state.lockedReason = null;
    },
    setLocked: (state, action) => {
      state.locked = action.payload.locked;
      state.lockedReason = action.payload.reason;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isAuthenticated = false;
      state.loading = false;
      state.user = null;
    },

    logout: (state) => {
      state = initialState
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
    },
  },
});

export const { setUser, setLoading, setError, logout, setLocked } = authSlice.actions;

export default authSlice.reducer;
