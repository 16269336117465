import React from "react";
import { Col, Container, Row } from "reactstrap";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import BasicContent from "../../components/BasicContent";
import { faBook } from "@fortawesome/pro-solid-svg-icons";

function KnowledgeBase() {
  const state = useSelector((state) => state);
  console.log("state:", state);
  return (
    <BasicContent title={"Base de Conhecimento"} icon={faBook}></BasicContent>
  );
}

export default KnowledgeBase;
