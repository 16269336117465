import React, { useEffect, useState } from "react";
import { Table, Container, Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { REPORTS } from "../../../../../api/REPORTS";
import moment from "moment-timezone";
import ReportLayout from "../../../../../components/ReportLayout";
import { GraphArea } from "../../styled";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GroupTicketsReport = ({ tenantId }) => {
  const [startDate, setStartDate] = useState(moment().subtract(7, "days").toDate());
  const [endDate, setEndDate] = useState(new Date());
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    fetchData();
    }, []);

  const fetchData = async () => {
    try {
      const response = await REPORTS.get.groups.tickets(
        startDate,
        endDate
      );
      console.log("Group performance data:", response);
      setGroupData(response);
    } catch (error) {
      console.error("Error fetching group performance data:", error);
    }
  };

  const chartData = {
    labels: groupData.map((item) => item.group_name || 'Sem grupo atribuído'),
    datasets: [
      {
        label: "Tickets Criados",
        data: groupData.map((item) => item.total_tickets),
        backgroundColor: "#007bff",
      },
      {
        label: "Tickets Resolvidos",
        data: groupData.map((item) => item.resolved_tickets),
        backgroundColor: "#28a745",
      },
    ],
  };

  return (
    <ReportLayout
    title="Relatório de Desempenho por Grupos"
    startDate={startDate}
    setStartDate={setStartDate}
    endDate={endDate}
    setEndDate={setEndDate}
    fetchData={fetchData}
  >
    <Container style={{ overflow: "auto" }}>
      
      {groupData.length > 0 && (
        <>
          <div style={{ maxHeight: "400px" }}>
            <GraphArea>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
              style={{ maxHeight: "400px" }}
            />
            </GraphArea>
          </div>
          <Row className="mt-4">
            <Col md={12}>
            <hr/>
              <h4>Detalhes</h4>
              <Table striped>
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>Tickets Criados</th>
                    <th>Tickets Resolvidos</th>
                    <th>Tempo Médio de Resolução</th>
                    <th>CSAT Médio</th>
                    <th>Avaliações (CSAT)</th>
                  </tr>
                </thead>
                <tbody>
                  {groupData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.group_name || 'Sem grupo atribuído'}</td>
                      <td>{item.total_tickets}</td>
                      <td>{item.resolved_tickets}</td>
                      <td> {item.avg_resolution_time
                                              ? moment
                                                  .duration(
                                                    parseFloat(item.avg_resolution_time),
                                                    "hours"
                                                  )
                                                  .humanize()
                                              : "-"}</td>
                      <td>{parseFloat(item.avg_csat)?.toFixed(2) || "-"}</td>
                      <td>{item.csat_count || 0}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Container>
    </ReportLayout>
  );
};

export default GroupTicketsReport;
