import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 100vh;
  gap: 1rem;
`;

const Sidebar = styled.div`
  grid-column: span 1;
  background-color: #f0f0f0;
  padding: 10px;

  background-color: #ffffff;
  border-radius: 8px;
  line-height: 1;
`;

const ReportArea = styled.div`
  grid-column: span 7;
  padding: 1rem;
  display: grid;
  gap: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
`;

const ReportSlot = styled.div`
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ReportLink = styled.div`
  margin-bottom: 1rem;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Output = styled.div`
  grid-column: span 7;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

export { Wrapper, Sidebar, ReportArea, ReportSlot, ReportLink, Output };
