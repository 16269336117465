import React, { useEffect, useState } from "react";

import * as S from "../../auth/styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import {
  faCheckCircle,
  faSearch,
  faSpinner,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { APP } from "../../../api/APP";
import { REQ_STATUS } from "../../../util/constants";
import {
  FH,
  Input,
  Label,
  Logo,
  Text,
  Title,
  TwoColumns,
} from "../../main-styles";
import { PatternFormat } from "react-number-format";
import { loadCNPJData, validaCNPJ } from "../../../util/functions";

function CompleteSignup() {
  const navigate = useNavigate();
  const { ws, code } = useParams();
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    name: "",
    cnpj: "",
    street: "",
    city: "",
    uf: "",
    country: "",
    public_name: "",
    search_status: REQ_STATUS.IDLE,
  });
  const {
    name,
    cnpj,
    street,
    city,
    uf,
    country,
    public_name,
    status,
    search_status,
  } = state;
  const { app } = appReducer;
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  useEffect(() => {
    init();
  }, []);

  async function init() {
    try {
      let response = await APP.get.should_validate(ws, code);
      console.log("RESPONSE", response);
      if (!response.error) {
        // focus on the first input
        document?.getElementById("workspace")?.focus();
        // title
        let windowtitle = "Help-Desk.app - Ativação de Workspace";
        document.title = windowtitle;
        setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
      }
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Workspace não encontrado ou já validado");
      navigate("/");
    }
  }

  async function validate() {
      console.log("STATUS", status);
    if (status !== REQ_STATUS.IDLE) return;
    console.log("STATUS", status);

    if (!name) {
      toast.error("Digite a razão social");
      return;
    }
    if (!cnpj) {
      toast.error("Digite o CNPJ");
      return;
    }
    if (!street) {
      toast.error("Digite o endereço");
      return;
    }
    if (!city) {
      toast.error("Digite a cidade");
      return;
    }
    if (!uf) {
      toast.error("Digite o estado");
      return;
    }
    if (!country) {
      toast.error("Digite o país");
      return;
    }
    if (!public_name) {
      toast.error("Digite o nome fantasia");
      return;
    }

    validateWS();
  }

  async function carregaDadosCNPJ() {
    if (search_status === REQ_STATUS.PENDING) return;
    setState((s) => ({
      ...s,
      search_status: REQ_STATUS.LOADING,
      // zera os dados
      name: "",
      public_name: "Buscando dados na receita federal...",
      street: "",
      city: "",
      uf: "",
      country: "",
    }));
    try {
      let response = await loadCNPJData(cnpj);
      console.log("RESPONSE cNpj data", response);
      /*
        {
        "name": "SEI DE COR TREINAMENTOS PARA EXPANSAO COMERCIAL LTDA",
        "fantasyName": "SEI DE COR TREINAMENTOS PARA EXPANSAO COMERCIAL",
        "phone": "(41)30156446",
        "city": "CURITIBA",
        "state": "PR",
        "country": "Brasil",
        "cnpj": "32.020.754/0001-08",
        "address": "RUA LYSIMACO FERREIRA DA COSTA",
        "number": "47",
        "email": "EDSONLIMA@SEIDECOR.COM.BR",
        "bairro": "CENTRO CIVICO",
        "zipCode": "80530100",
        "geoLocation": {
            "lat": -25.4180415,
            "lng": -49.2661472
        }
    }
        */
      // fill data
      setState((s) => ({
        ...s,
        name: response.name,
        public_name: response.fantasyName,
        street: `${response.address}, ${response.number}`,
        city: response.city,
        uf: response.state,
        country: response.country,
        search_status: REQ_STATUS.SUCCESS,
      }));
    } catch (error) {
      console.log("ERROR", error);
      toast.error("CNPJ não encontrado");
      setState((s) => ({ ...s, search_status: REQ_STATUS.IDLE }));
    }
  }

  async function validateWS() {
    setState((s) => ({ ...s, status: REQ_STATUS.PENDING }));
    console.log("CREATE WS");
    try {
      let response = await APP.set.cadastro_completo(ws, {
        name,
        cnpj,
        street,
        city,
        state: uf,
        country,
        public_name,
        code,
      });
      console.log("RESPONSE", response);
      setState((s) => ({ ...s, status: REQ_STATUS.SUCCESS }));
      console.log("SUCCESSO!!");
    } catch (error) {
      console.log("ERROR", error);
      toast.error("Workspace não encontrado ou já validado");
      setState((s) => ({ ...s, status: REQ_STATUS.IDLE }));
    }
  }

  if (status === REQ_STATUS.SUCCESS) {
    // full screen success, check email

    return (
      <S.WorkspaceContainer backgroundColor={backgroundColor}>
        <S.WorkspaceWhiteContainer style={{ alignItems: "center" }} full={true}>
          <Logo src={app?.darklogo || mainlogo} />
          <div style={{ borderBottom: "2px solid #dddddd", width: "50%" }} />
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ fontSize: 50, color: "#00b324" }}
          />
          <Title>Workspace Ativo!</Title>
          <Text style={{ marginTop: 20 }}>
            Efetue login para acessar o painel
          </Text>
          <Button
            onClick={() =>
              (window.location.href = `https://${ws}.help-desk.app/auth/sign-in`)
            }
          >
            Acessar painel
          </Button>
        </S.WorkspaceWhiteContainer>
      </S.WorkspaceContainer>
    );
  }

  return (
    <S.WorkspaceContainer backgroundColor={backgroundColor}>
      <S.WorkspaceWhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Title style={{ marginTop: 30 }}>Ativação de workspace</Title>
        <Text style={{ textAlign: "left", width: "100%", marginTop: 20 }}>
          Vamos ativar o workspace de sua empresa
        </Text>
        <Or text="Dados da empresa" color="#515151" />{" "}
        <div style={{ width: "100%" }}>
          <Label>CNPJ</Label>
          <FH>
            <PatternFormat
              format={"##.###.###/####-##"}
              mask="_"
              value={cnpj}
              onChange={(e) =>
                setState({
                  ...state,
                  cnpj: e.target.value,
                  search_status: REQ_STATUS.IDLE,
                  // clear address
                  street: "",
                  city: "",
                  uf: "",
                  country: "",
                  name: "",
                  public_name: "",
                })
              }
              onBlur={(e) => {
                if (!validaCNPJ(cnpj)) {
                  toast.error("CNPJ inválido");
                } else {
                  carregaDadosCNPJ();
                }
              }}
              customInput={Input}
            />
            <Button
              style={{ width: 50 }}
              onClick={() => {
                carregaDadosCNPJ();
              }}
            >
              <FontAwesomeIcon
                icon={
                  search_status === REQ_STATUS.LOADING ? faSpinner : faSearch
                }
                spin={search_status === REQ_STATUS.LOADING}
              />
            </Button>
          </FH>
        </div>
        <div style={{ width: "100%" }}>
          <Label>
            Nome Fantasia - Será usado como nome público de sua empresa
          </Label>
          <Input
            type="text"
            value={public_name}
            disabled={search_status !== REQ_STATUS.SUCCESS}
            onChange={(e) =>
              setState({ ...state, public_name: e.target.value })
            }
          />
        </div>
        <div style={{ width: "100%" }}>
          <Label>Razão Social</Label>
          <Input
            type="text"
            value={name}
            disabled={true}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
        </div>
        <Or text="Endereço" color="#515151" />
        <div style={{ width: "100%" }}>
          <Label>Endereço</Label>
          <Input
            type="text"
            placeholder="Rua, Número"
            disabled={search_status !== REQ_STATUS.SUCCESS}
            value={street}
            onChange={(e) => setState({ ...state, street: e.target.value })}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Label>Cidade</Label>
          <Input
            type="text"
            placeholder="Cidade"
            disabled={search_status !== REQ_STATUS.SUCCESS}
            value={city}
            onChange={(e) => setState({ ...state, city: e.target.value })}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Label>Estado</Label>
          <Input
            type="text"
            placeholder="Estado"
            value={uf}
            disabled={search_status !== REQ_STATUS.SUCCESS}
            onChange={(e) => setState({ ...state, uf: e.target.value })}
          />
        </div>
        <div style={{ width: "100%" }}>
          <Label>País</Label>
          <Input
            type="text"
            placeholder="País"
            value={country}
            disabled={search_status !== REQ_STATUS.SUCCESS}
            onChange={(e) => setState({ ...state, country: e.target.value })}
          />
        </div>
        <Button onClick={validate}>
            {status === REQ_STATUS.PENDING ? 'Ativando...Aguarde...' :
            'Ativar conta'}</Button>
      </S.WorkspaceWhiteContainer>
    </S.WorkspaceContainer>
  );
}

export default CompleteSignup;
