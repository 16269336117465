import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../../api/REPORTS";
import moment from "moment-timezone";

// Registrar componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const AgentPerformanceReport = ({ tenantId }) => {
  const [performanceData, setPerformanceData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    fetchPerformanceData();
  }, []);

  const fetchPerformanceData = async () => {
    // make sure dates exist
    if (!startDate || !endDate) return;
    try {
      const response = await REPORTS.get.agents.performance(
        startDate.toISOString(),
        endDate.toISOString(),
        tenantId
      );
      console.log("Performance data:", response);
      setPerformanceData(response.data);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    }
  };

  const chartData = {
    labels: performanceData.map((item) => item.agent_name || "Sem Dados"),
    datasets: [
      {
        label: "Tickets Resolvidos",
        data: performanceData.map((item) => item.tickets_resolved),
        backgroundColor: "#007bff",
      },
    ],
  };

  return (
    <Container style={{ overflow: "auto" }}>
      <h2 className="mt-4">Relatório de Performance de Agentes</h2>
      <Row className="my-4">
        <Col md={4}>
          <FormGroup>
            <Label for="startDate">Data Inicial</Label>
            <Input
              type="date"
              id="startDate"
              value={startDate.toISOString().split("T")[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="endDate">Data Final</Label>
            <Input
              type="date"
              id="endDate"
              value={endDate.toISOString().split("T")[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <Button color="primary" onClick={fetchPerformanceData}>
            Atualizar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false, // Permite definir uma altura personalizada
            }}
            style={{ maxHeight: "400px" }} // Limita a altura do gráfico
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <h4>Detalhes de Performance</h4>
          <Table striped>
            <thead>
              <tr>
                <th>Agente</th>
                <th>Grupos</th>
                <th>Tickets Resolvidos</th>
                <th>Tempo Médio de Resolução</th>
                <th>CSAT Médio</th>
              </tr>
            </thead>
            <tbody>
              {performanceData.map((item, index) => {
                console.log("item", item);
                const avg_csat = parseFloat(item.avg_csat);
                const avg_resolution_time = parseFloat(
                  item.avg_resolution_time
                );
                return (
                  <tr key={index}>
                    <td>{item.agent_name || "Agente Não Identificado"}</td>

                    <td>{item.groups || "Sem Grupos"}</td>
                    <td>{item.tickets_resolved} ({item.total_responses} respostas)</td>
                    <td>
                      {item.avg_resolution_time
                        ? moment
                            .duration(
                              parseFloat(item.avg_resolution_time),
                              "hours"
                            )
                            .humanize()
                        : "-"}
                    </td>
                    <td>{avg_csat?.toFixed(2) || "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AgentPerformanceReport;
