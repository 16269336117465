import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  margin-bottom: 40px;
`;

export const Campo = styled.div`
  // make it occupy 4 columns
  grid-column: span 4;
`;

export const Ajuda = styled.div`
  // make it occupy 1 columns
  color: #ffffff;
  font-size: 0.8em;
  padding: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  background: #6c6c6c;
`;
