import React from "react";

import * as S from "../styles";
import logo from "../../../assets/helpdesk/logo-small.png";
import { Col } from "reactstrap";
import { Button } from "../../main-styles";
import COLORS from "../../../util/COLORS";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPortalEnter, faSigning } from "@fortawesome/pro-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(path);
  }

  function scrollToId(id){
    document.getElementById(id).scrollIntoView({behavior: 'smooth'});
  }

  return (
    <Col md={12}>
      <S.Nav>
        <S.LogoContainer>
          <S.Logo src={logo} />
        </S.LogoContainer>
        <S.Options>
          <div onClick={()=>scrollToId('FAQ')}>
            FAQ
          </div>
          <div onClick={()=>scrollToId('pricing')}>
            Precificação
          </div>
        </S.Options>
        <S.ActionButtons>
          <Button
            backgroundColor={COLORS.grey}
            className="animate__animated animate__backInDown"
            onClick={() => navigateTo("/auth/sign-in")}
          >
            Acessar
            <FontAwesomeIcon icon={faPortalEnter} style={{ marginLeft: 5 }} />
          </Button>
          <Button
            className="animate__animated animate__backInDown animate__delay-1s"
            onClick={() => navigateTo("/br/workspace/new")}
          >
            Começar de graça
          </Button>
        </S.ActionButtons>
      </S.Nav>
    </Col>
  );
}

export default Header;
