import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import {
  Wrapper,
  Sidebar,
  ReportArea,
  ReportSlot,
  ReportLink,
  Output,
  Links,
} from "./styled.js";
import { TitleContainer } from "../styles.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/pro-solid-svg-icons";

const reports = [
  { id: "/csat", name: "Relatório de CSAT" },
  { id: "/tickets", name: "Desempenho de Tickets" },
  { id: "/agents/performance", name: "Performance de Agentes" },
  { id: "/groups/performance", name: "Tickets por Grupo" },
  { id: "/groups/tickets", name: "Desempenho por Grupo" },
  // { id: "5", name: "Problemas Recorrentes" },
  // { id: "6", name: "SLA" },
  // { id: "7", name: "Uso por Tenant" },
  // { id: "8", name: "Feedbacks Negativos" },
];

const App = () => {
  const navigate = useNavigate();

  function goto(path) {
    navigate(path);
  }

  return (
    <Wrapper>
      {/* Sidebar com a lista de relatórios */}
      <Sidebar>
        <TitleContainer bottom={true}>Relatórios</TitleContainer>
        <Links>
        {reports.map((report) => (
          <ReportLink
          key={report.id}
          onClick={() => goto(`/agents/reports${report.id}`)}
          >
            <FontAwesomeIcon icon={faChevronRight} />{report.name}
          </ReportLink>
        ))}
        </Links>
      </Sidebar>

      {/* Área de Relatórios */}
      <ReportArea>
        <Outlet />
      </ReportArea>
    </Wrapper>
  );
};

export default App;
