import api from "../axios"
import { store } from "../../redux/configureStore";

export const USERS = {
    get: {
        me: async() => {
            let data = await api.get("/users/me");
            return data.data;
        },
        all: async() => {
        },
        one: async(id) => {
        },
        check: async() => {
        },
        agents: async()=>{
            const tid = store.getState().appReducer.app.tid;
            const response = await api.get(`/users/agents/${tid}`);
            return response.data;   
        }
        

    }, 
    set: {
        new: async(data) => {
            const tid = store.getState().appReducer.app.tid;
            const response = await api.post(`/users/user/${tid}`, data);
            return response.data
        },
        update: async(id, data) => {
        },
        delete: async(id) => {
        },
        activate: async(id) => {
            const response = await api.post(`/users/activate/${id}`);
            return response;
        },
    }
}