import React, { useEffect, useState } from "react";

import {
  faCheckSquare,
  faSquare,
  faTicket,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { TicketsContext } from "..";
import { TICKETS } from "../../../../api/TICKETS";
import { getTicketStatusAgent } from "../../../../util/functions";
import { Description, FH } from "../../../main-styles";
import { useNavigate } from "react-router-dom";
import { AGENT_TICKET_PRIORITY_ORDER } from "../../../../util/constants";
import LineUser from "../../../../components/LineUser";

function List() {
  const context = React.useContext(TicketsContext);
  const { state, selectTicket, updateField } = context;
  const { page, perPage, currentTicket, agents } = state;
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [onlyMine, setOnlyMine] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadMoreTickets(true);
  }, [showClosed, onlyMine]);

  async function loadMoreTickets(init) {
    if (!hasMore && !init) return;
    setLoading(true);
    // load tickets
    if (init) updateField("page", 0);
    const newTickets = init
      ? await TICKETS.get.agents.all(0, perPage, showClosed, onlyMine)
      : await TICKETS.get.agents.all(page, perPage, showClosed, onlyMine);
    // concat only non existant tickets
    setTickets((t) => {
      if (init) return newTickets;
      return t.concat(
        newTickets.filter((nt) => !t.find((t) => t.id === nt.id))
      );
    });
    updateField("page", page + 1);
    if (init) {
      const ticket = newTickets[0];

      if (ticket) {
        navigate(`/agents/tickets/${ticket.id}`);
      }
    }
    if (newTickets.length < perPage) {
      setHasMore(false);
    }
    setLoading(false);
  }

  // function load more on scroll
  window.onscroll = function () {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      loadMoreTickets();
    }
  };

  function ordenaTickets(tickets) {
    // primeiro ordena por data de atualização
    tickets = tickets.sort((a, b) => {
      return new Date(b.last_update) - new Date(a.last_update);
    });
    // depois ordena por status de prioridade
    tickets = tickets.sort((a, b) => {
      return (
        AGENT_TICKET_PRIORITY_ORDER.indexOf(a.status) -
        AGENT_TICKET_PRIORITY_ORDER.indexOf(b.status)
      );
    });

    return tickets;
  }

  const ticketsOrdenados = ordenaTickets(tickets);

  return (
    <TicketList>
      <FH
        onClick={() => setShowClosed(!showClosed)}
        style={{
          margin: 5,
          padding: 5,
          cursor: "pointer",
          border: "1px solid #ffffff",
          borderRadius: "5px 5px 0px 0px",
          backgroundColor: "#fff",
        }}
      >
        <FontAwesomeIcon
          style={{ fontSize: 10 }}
          icon={showClosed ? faCheckSquare : faSquare}
          color={showClosed ? "#00b221ff" : "#fcfcfc"}
        />
        <Description style={{ fontSize: 10 }}>Mostrar fechados</Description>
      </FH>
      {ticketsOrdenados.map((ticket, i) => {
        // console.log(" ticket.status", ticket.status);
        let colorinfo = getTicketStatusAgent(ticket.status);
        // console.log("colorinfo", colorinfo);
        const agent = agents.find((a) => parseInt(a.id) === ticket.agent);
        return (
          <Ticket
            selected={currentTicket === ticket.id}
            onClick={() => selectTicket(ticket.id)}
            color={colorinfo.color}
          >
            <FH style={{ justifyContent: "space-between" }}>
              <TicketTitle>
                <FontAwesomeIcon
                  icon={faTicket}
                  style={{ color: colorinfo.color }}
                />
                {ticket.name}
              </TicketTitle>

              <TicketDate
                title={moment(ticket.last_update).format("DD/MM/YYYY HH:mm:ss")}
              >
                {moment(ticket.last_update).fromNow()}
              </TicketDate>
            </FH>
           
            <TicketDescription style={{ color: colorinfo.color }}>
              {colorinfo.status}
            </TicketDescription>
            <TicketDescription>
              #{ticket.id} - {ticket.subject}
            </TicketDescription>
            {agent && <LineUser image={agent.thumbnail} name={`Responsável: ${agent.name}`} />}
          </Ticket>
        );
      })}
      {tickets.length === 0 && !loading && (
        <Ticket>Nenhum ticket selecionado</Ticket>
      )}
      {loading && (
        <Ticket>
          <Spinner />
        </Ticket>
      )}
    </TicketList>
  );
}

const TicketList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // full height
  padding: 4px;
  padding-right: 0;
  gap: 3px;
  /* border-right: 5px solid #fff; */
  height: 100px;
`;

const Ticket = styled.div`
  padding: 1rem;
  background-color: ${(props) => (props.selected ? "#fff" : "transparent")};
  // if selected should look like this
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    `

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-left: 15px solid ${props.color || "#ccc"};
    cursor: default;
  `}
`;

const TicketTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  display: flex;
  gap: 5px;
  align-items: center;
  // elipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TicketDate = styled.div`
  font-size: 11px;
  color: #c4c4c4;
  // flot right
  float: right;
`;

const TicketDescription = styled.div`
  font-size: 11px;
  color: #333;

  align-items: center;
  // elipsis
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default List;
