import {
  faCheck,
  faCircle,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faHistory,
  faImage,
  faInfoCircle,
  faMessage,
  faPaperclip,
  faSparkles,
  faTicket,
  faTimes,
  faTrafficCone,
  faTrash
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import createDOMPurify from "dompurify";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Spinner } from "reactstrap";
import styled from "styled-components";
import { TICKETS } from "../../../api/TICKETS";
import { UTILS } from "../../../api/UTILS";
import BasicContent from "../../../components/BasicContent";
import CSAT from "../../../components/CSAT";
import GotoBottomButton from "../../../components/GotoBottomButton";
import WYSEditor from "../../../components/WYSEditor";
import {
  REQ_STATUS,
  TICKET_STATUS,
  TICKET_STATUS_NAMES,
} from "../../../util/constants";
import * as S from "../../main-styles";

const DOMPurify = createDOMPurify(window);

function TicketView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { colors } = useSelector((state) => state.appReducer.app);
  const { user } = useSelector((state) => state.authReducer);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    answer: "",
    files: [],
  });
  const { status, data, answer, files } = state;

  useEffect(() => {
    load();
  }, [id]);

  async function load() {
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    const data = await TICKETS.get.one(id);

    console.log("TICKETS", data);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE, data }));
  }

  function setAnswer(answer) {
    console.log("ANSWER", answer);
    setState((s) => ({ ...s, answer }));
  }

  function addFile(files) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({ ...s, files: [...s.files, ...files] }));
  }

  function removeFile(index) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({
      ...s,
      files: s.files.filter((_, i) => i !== index),
    }));
  }

  async function submit() {
    if (status !== REQ_STATUS.IDLE) return;
    if (!answer) {
      return toast.error("Digite uma resposta");
    }
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));

    // SE O STATUS DO TICKET FOR CREATED, DEVE MANTER CREATED
    // SE O STATUS DO TICKET FOR QUALQUER OUTRO, DEVE SER SEMPRE QUEUED
    console.log("STATUS ATUAL", data?.status);
    const newStatus =
      data?.status === TICKET_STATUS.CREATED
        ? TICKET_STATUS.CREATED
        : TICKET_STATUS.QUEUED;

    let files = await uploadImages();

    const newData = await TICKETS.set.update(id, answer, newStatus, files);
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        thread: [...s.data.thread, newData],
        status: newStatus,
      },
      files: [],
      answer: "",
    }));
  }

  function closeTicketConfirm() {
    confirmAlert({
      title: "Fechar o ticket",
      message: "Tem certeza que deseja marcar o ticket como fechado?",
      buttons: [
        {
          label: "Sim, fechar o ticket",
          onClick: closeTicket,
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function uploadImages() {
    // pega um signed url para upload por imagem
    const renamedFiles = files.map((file) => {
      // add a huge random string to the file name to avoid conflicts
      const randomString = moment().format("YYYYMMDDHHmmss")
      return new File([file], `${randomString}_${file.name}`, {
        type: file.type,
      });
    });
    let promises = await UTILS.get.singed_url(renamedFiles);
    console.log("PROMISES", promises);
    // faz o upload da imagem
    let uploads = await promises.map(async (item, i) => {
      let response = await UTILS.set.upload_file(
        item.signed_url,
        renamedFiles[i]
      );
      console.log("RESPONSE", response);
      return item.url;
    });
    uploads = await Promise.all(uploads);
    console.log("UPLOADS", uploads);
    return uploads;
  }

  async function closeTicket() {
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    const newStatus = TICKET_STATUS.CLOSED;
    const newData = await TICKETS.set.updateStatus(id, newStatus);
    toast.success("Ticket fechado com sucesso");
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        status: newStatus,
      },
    }));
  }

  function resolveTicketConfirm() {
    confirmAlert({
      title: "Resolver o ticket",
      message: "Tem certeza que deseja marcar o ticket como resolvido?",
      buttons: [
        {
          label: "Sim, marcar como resolvido",
          onClick: () => resolveTicket(),
          style: {
            backgroundColor: "#039f3c",
          },
        },
        {
          label: "Não",
          onClick: () => {},
          style: {
            backgroundColor: "#7c7c7c",
          },
        },
      ],
    });
  }

  async function resolveTicket() {
    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    const newStatus = TICKET_STATUS.RESOLVED;
    const newData = await TICKETS.set.updateStatus(id, newStatus);
    toast.success("Ticket resolvido com sucesso");
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      data: {
        ...s.data,
        status: newStatus,
      },
    }));
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <BasicContent title="Carregando">
        <Spinner />
      </BasicContent>
    );
  }

  let icon = faTicket;
  let color = "#ccc";
  let statusname = "Desconhecido";
  switch (data.status) {
    case TICKET_STATUS.CREATED:
      icon = faEnvelope;
      color = "#85aef7";
      statusname = "Enviado";
      break;

    case TICKET_STATUS.QUEUED:
      icon = faEnvelopeOpen;
      color = "#ffb151";
      statusname = "Na fila";
      break;

    case TICKET_STATUS.IN_PROGRESS:
      color = "#8d2ccb";
      icon = faTrafficCone;
      statusname = "Em andamento";
      break;

    case TICKET_STATUS.CLOSED:
      icon = faTimes;
      color = "#ccc";
      statusname = "Fechado";
      break;

    case TICKET_STATUS.RESOLVED:
      icon = faCheck;
      color = "#039f3c";
      statusname = "Resolvido";
      break;

    case TICKET_STATUS.WAITING_CUSTOMER:
      color = "#f54323";
      icon = faExclamationCircle;
      statusname = "Aguardando você";
      break;

    default:
      break;
  }

  const CAN_ANSWER = !(
    data.status === TICKET_STATUS.CLOSED ||
    data.status === TICKET_STATUS.RESOLVED
  );

  return (
    <BasicContent
      title={`#${data?.id} - ${data.subject}`}
      icon={faTicket}
      back={() => navigate("/tickets")}
    >
      <GotoBottomButton />
      <Info color={color}>
        <S.Title style={{ textTransform: "capitalize" }}>
          {data.subject}
        </S.Title>
        <S.Description
          style={{ color: "#ababab", fontSize: "0.8rem" }}
          title={moment(data.created_at).format("DD/MM/YYYY HH:mm")}
        >
          {moment(data.created_at) < moment().add(-3, "days")
            ? moment(data.created_at).format("DD/MM/YYYY HH:mm")
            : moment(data.created_at).fromNow()}
        </S.Description>
        {data?.short_description !== "Gerando resumo..." && (
          <S.Description>{data?.short_description}</S.Description>
        )}
      </Info>
      <Main>
        <Thread borderColor={color}>
          <Reply
            created_at={data.created_at}
            description={data.description}
            username={"ME"}
            user_id={data.user_id}
            thumbnail={user?.thumbnail}
            files={data?.files}
          />
          {data.thread?.map((item, index) => {
            if (item.type === "status_update") {
              return (
                <StatusUpdate
                  created_at={item.date}
                  description={item.content}
                  username={item.name}
                  user_id={item.created_by}
                />
              );
            }
            return (
              <Reply
                created_at={item.date}
                description={item.content}
                username={item.name}
                user_id={item.created_by}
                files={item.files}
                thumbnail={item.thumbnail}
              />
            );
          })}
          {CAN_ANSWER ? (
            <>
              <ThreadItem>
                <S.Description>Opções de ticket</S.Description>
                <S.FH>
                  <S.Button
                    style={{ padding: 10 }}
                    backgroundColor={"#039f3c"}
                    onClick={resolveTicketConfirm}
                  >
                    <FontAwesomeIcon icon={faCheck} /> Marcar como resolvido
                  </S.Button>
                  <S.Button
                    style={{ padding: 10 }}
                    backgroundColor={"#9f0303"}
                    onClick={closeTicketConfirm}
                  >
                    <FontAwesomeIcon icon={faTimes} /> Fechar o ticket
                  </S.Button>
                </S.FH>
              </ThreadItem>
              <ThreadItem>
                <S.FH style={{ justifyContent: "space-between" }}>
                  <S.Label style={{ marginBottom: 10 }}>
                    <FontAwesomeIcon icon={faMessage} /> Responder
                  </S.Label>
                  {answer.length > 50 && (
                    <S.Label
                      className="animate__animated animate__tada"
                      style={{ marginBottom: 10, color: "purple" }}
                    >
                      <FontAwesomeIcon icon={faSparkles} /> Organizar as idéias
                    </S.Label>
                  )}
                </S.FH>
                <WYSEditor
                  value={answer}
                  onChange={setAnswer}
                  disabled={status === REQ_STATUS.SENDING}
                />
                <hr />
                <S.Description>
                  <FontAwesomeIcon icon={faPaperclip} />
                  Anexos (somente imagens)
                </S.Description>
                <label
                  htmlFor="file-upload"
                  style={{
                    cursor: "pointer",

                    border: "1px dashed #666666",
                    backgroundColor: "#f9f9f9",
                    borderRadius: 5,
                    padding: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#666",
                    gap: 5,
                  }}
                >
                  <FontAwesomeIcon icon={faImage} />
                  Clique para enviar uma imagem
                </label>
                <input
                  id="file-upload"
                  type="file"
                  multiple={true}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => addFile(e.target.files)}
                  disabled={status === REQ_STATUS.SENDING}
                />
                {files.length > 0 && <hr />}
                {files.map((file, index) => (
                  <S.FH key={index} style={{ justifyContent: "space-between" }}>
                    <S.Text>{file.name}</S.Text>
                    <div
                      style={{
                        flex: 1,
                        borderBottom: `1px dotted #ccc`,
                        height: `1rem`,
                        marginLeft: 10,
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                    />
                    <S.Text onClick={() => removeFile(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </S.Text>
                  </S.FH>
                ))}
                <hr />
                <S.Button
                  backgroundColor={
                    status === REQ_STATUS.SENDING ? "#ccc" : colors.main
                  }
                  onClick={submit}
                  style={{ marginTop: 10 }}
                >
                  {status === REQ_STATUS.SENDING ? "Enviando" : "Enviar"}
                </S.Button>
              </ThreadItem>
            </>
          ) : (
            <>
              <ThreadItem style={{ backgroundColor: "#e3eefe87", border: 0 }}>
                <CSAT
                  id={data.id}
                  csat={
                    data?.score && {
                      score: data.score,
                      solved: data.solved,
                    }
                  }
                />
              </ThreadItem>
              <ThreadItem style={{ opacity: 0.3 }}>
                <S.FH>
                  <FontAwesomeIcon icon={faInfoCircle} /> Este ticket não pode
                  ser respondido, pois está finalizado.
                </S.FH>
              </ThreadItem>
            </>
          )}
        </Thread>
        <History>
          <div
            style={{
              marginLeft: -20,
              marginTop: 15,
              marginBottom: 10,
              backgroundColor: color,
              color: "#fff",
              padding: 15,
              borderBottomRightRadius: 10,
              borderTopRightRadius: 10,
            }}
          >
            <S.FH>
              <FontAwesomeIcon icon={icon} size="2x" />
              <S.Text
                style={{ fontWeight: "bold", color: "white", marginLeft: 5 }}
              >
                {statusname}
              </S.Text>
            </S.FH>
          </div>
          <S.Text
            style={{
              borderBottom: "1px solid #cccccc74",
              fontWeight: "bold",
              color: "#ccc",
            }}
          >
            <FontAwesomeIcon icon={faHistory} /> Histórico
          </S.Text>
          <HistoryEntry>
            <S.FH>
              <FontAwesomeIcon icon={faCircle} />
              Criado
            </S.FH>
            <span>{moment(data.created_at).format("DD/MM/YYYY HH:mm")}</span>
          </HistoryEntry>
          {data?.thread
            ?.filter((item) => item.type === "status_update")
            .map((item, index) => {
              return (
                <HistoryEntry>
                  <S.FH>
                    <FontAwesomeIcon icon={faCircle} />
                    {TICKET_STATUS_NAMES[item.content]}
                  </S.FH>
                  <span>{moment(item.date).format("DD/MM/YYYY HH:mm")}</span>
                </HistoryEntry>
              );
            })}
        </History>
      </Main>
    </BasicContent>
  );
}

function Reply({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const mine = parseInt(user.id) === parseInt(user_id);
  const [selected, setSelected] = useState(null);
  return (
    <ThreadItem mine={mine}>
      <S.FH>
        <ThreadUserImg
          src={
            thumbnail
              ? thumbnail
              : "https://img.seidecor.com.br/fit-in/150x150/-LIG_iJcJTGJZwPKMJiZ/generated-images/generated_zmxo1.png"
          }
        />
        <div>
          <S.Description style={{ fontWeight: "bold" }}>
            {mine ? "Você" : username}
          </S.Description>
          <S.Description
            style={{ color: "#ababab", fontSize: "0.8rem" }}
            title={moment(created_at).format("DD/MM/YYYY HH:mm")}
          >
            {moment(created_at) < moment().add(-3, "days")
              ? moment(created_at).format("DD/MM/YYYY HH:mm")
              : moment(created_at).fromNow()}
          </S.Description>
        </div>
      </S.FH>

      <hr />
      <S.Text
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description),
        }}
      />
      {files && files.length > 0 && (
        <>
          <hr />
          <S.Description>
            <FontAwesomeIcon icon={faPaperclip} /> Anexos
          </S.Description>
          <S.FH style={{ gap: 25, flexWrap: "wrap" }}>
            {files.map((file, index) => {
              const filename = file.split("/").pop();
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 5,
                    fontSize: "0.8rem",
                    color: "#ababab",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelected(file)}
                >
                  <img
                    src={file}
                    alt={filename}
                    style={{
                      width: 100,
                      height: 100,
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      cursor: "pointer",
                      // cover
                      objectFit: "cover",
                      // center
                      objectPosition: "center",
                      // elipsis text
                    }}
                  />
                  <span
                    style={{
                      maxWidth: 100,
                      // elipsis long text
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexWrap: "nowrap",
                      textWrap: "nowrap",
                      fontSize: "0.7rem",
                    }}
                  >
                    {filename}
                  </span>
                </div>
              );
            })}
          </S.FH>
        </>
      )}
      <Modal
        isOpen={selected !== null}
        toggle={() => setSelected(null)}
        size="lg"
      >
        {selected && (
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={selected}
              alt="Anexo"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <span
              style={{
                fontSize: "0.8rem",
                color: "#ababab",
              }}
            >
              {selected?.split("/").pop()}
            </span>
          </div>
        )}
      </Modal>
    </ThreadItem>
  );
}

function StatusUpdate({
  created_at,
  description,
  username,
  thumbnail,
  user_id,
  files,
}) {
  const user = useSelector((state) => state.authReducer.user);
  const me = parseInt(user.id) === parseInt(user_id);
  return (
    <StatusUpdateContainer>
      <strong>{me ? "Você" : username}</strong> marcou o ticket como{" "}
      <strong>"{TICKET_STATUS_NAMES[description]}"</strong> em{" "}
      <strong>{moment(created_at).format("DD/MM/YYYY HH:mm")}</strong>
    </StatusUpdateContainer>
  );
}

const HistoryEntry = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1;
  color: #7c7c7c;
  & > span {
    font-size: 0.8rem;
    color: #ababab;
  }
`;

const StatusUpdateContainer = styled.div`
  padding: 1rem;
  color: #ababab;
  font-size: 0.9rem;
  background-color: #f9f9f9;
  border-radius: 10px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: 2rem solid ${(props) => props.color || "#ccc"};
  line-height: 1.3;

  box-shadow: 0px 0px 6px 0px #00000011;
  border-radius: 10px;
`;

const Main = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 0px;
`;

const Thread = styled.div`
  grid-area: 1 / 1 / 2 / 5;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 15px;
  margin-bottom: 3rem;
  // first children should have double border size
  & > div:first-child {
    border: 4px solid ${(props) => props.borderColor || "#ccc"};

    box-shadow: 0px 0px 6px 0px #00000011;
  }
`;

const History = styled.div`
  grid-area: 1 / 5 / 2 / 6;
`;

const ThreadItem = styled.div`
  border: ${(props) =>
    props.mine ? "2px solid #e6e6e6" : "1px solid #cccccc"};
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
  gap: 0.5rem;
`;

const ThreadUserImg = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TicketID = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.color};
  text-align: center;
  border-right: 1px solid #eaeaea;
`;

export default TicketView;
