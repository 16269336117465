import api from "../axios";
import axios from "axios";
import { store } from "../../redux/configureStore";
export const UTILS = {
  get: {
    singed_url: async (files = [], bucket) => {
      const tid = store.getState().appReducer.app.tid;
      console.log("FILES", files);
      const files_details = files.map((file) => {
        return {
          filename: file.name,
          filetype: file.type,
        };
      });
      console.log("FILES DETAILS", files_details);
      if (!bucket) bucket = `sdc-img-server/hd-app/${tid}`;
      let response = await api.post(`/utils/signed-url`, {
        bucket,
        files_details,
      });
      const finalURLs = response.data.map((item, i) => {
        let uu = bucket.replace(`sdc-img-server/`, "");
        let filename = files[i].name;
        let imgurl = `https://img.seidecor.com.br/fit-in/500x500/${uu}/${filename}`;

        return {
          signed_url: item,
          url: imgurl,
        };
      });
      return finalURLs;
    },
  },
  set: {
    // upload to signed url s3 bucket with progress
    upload_file: async (signed_url, file) => {
      let response = await axios.put(signed_url, file, {
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(percentCompleted);
        },
      });
      return response;
    },
  },
};
