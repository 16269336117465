import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

function BigSelect({ options, selected, onSelect }) {
  const { colors } = useSelector((state) => state.appReducer?.app);
  return (
    <>
      <Container>
        {options.map((option, i) => (
          <Option
            className="animate__bounceIn"
            key={i}
            selected={selected === option.value}
            color={colors.main}
            textColor={colors.headerText}
            onClick={() => onSelect(option.value)}
          >
            {option.icon && <FontAwesomeIcon icon={option.icon} />}
            {option.text}
          </Option>
        ))}
      </Container>
      <hr style={{ color: colors.main }} />
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
`;
const Option = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 8px;
  border-style: solid;
  background-color: ${(props) => props.color || "black"};
  color: ${(props) => props.textColor || "white"};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  gap: 0.5rem;
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
  &:hover {
    background-color: ${(props) => `${props.color}CC` || "darkgray"};

    box-shadow: 0px 0px 10px 0px #0000001e;
  }
`;

export default BigSelect;
