import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../api/REPORTS";

// Registrar os componentes necessários do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CSATReport = ({ tenantId }) => {
  const [csatData, setCsatData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  // Função fornecida para buscar dados
  const fetchCSATData = async () => {
    try {
      const response = await REPORTS.get.agents.csat(
        startDate,
        endDate,
        tenantId
      );
      console.log("CSAT data:", response.data);
      setCsatData(response.data);
    } catch (error) {
      console.error("Error fetching CSAT data:", error);
    }
  };

  useEffect(() => {
    fetchCSATData();
  }, [startDate, endDate]);

  // Preparar dados para o gráfico
  const chartData = {
    labels: csatData.map((item) => new Date(item.day).toLocaleDateString()),
    datasets: [
      {
        label: "CSAT Média",
        data: csatData.map((item) => item.avg_csat),
        fill: false,
        borderColor: "#007bff",
        tension: 0.1,
      },
    ],
  };

  return (
    <Container style={{ overflow: "auto" }}>
      <h2 className="mt-4">Relatório de Satisfação do Cliente</h2>
      <Row className="my-4">
        <Col md={4}>
          <FormGroup>
            <Label for="startDate">Data Inicial</Label>
            <Input
              type="date"
              id="startDate"
              value={startDate.toISOString().split("T")[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="endDate">Data Final</Label>
            <Input
              type="date"
              id="endDate"
              value={endDate.toISOString().split("T")[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <Button color="primary" onClick={fetchCSATData}>
            Atualizar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Line data={chartData}
            // max height of the chart
            options={{
              responsive: true,
              maintainAspectRatio: false, // Permite definir uma altura personalizada
            }}
            style={{ maxHeight: "400px" }} // Limita a altura do gráfico
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <h4>Detalhes</h4>
          <Table striped>
            <thead>
              <tr>
                <th>Data</th>
                <th>Média de CSAT</th>
                <th>Total de Respostas</th>
              </tr>
            </thead>
            <tbody>
              {csatData.map((item, index) => {
                console.log('item',item);
                // from string to number 

                const avg_csat=parseFloat(item.avg_csat);
                return <tr key={index}>
                  <td>{new Date(item.day).toLocaleDateString()}</td>
                  <td>{avg_csat?.toFixed(2)}</td>
                  <td>{item?.total_responses}</td>
                </tr>;
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default CSATReport;
