import axios from 'axios';
import { API_URL } from '../util/constants';

// Create an instance of Axios
const api = axios.create({
    baseURL: API_URL, // Replace with your API base URL
});

// Add a request interceptor to attach the token to each request
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Add a response interceptor to handle token refresh
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        let refreshToken = localStorage.getItem('refreshToken');
        
        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            refreshToken
        ) {
            originalRequest._retry = true;

            try {
                // Make a request to refresh the token
                const response = await axios.post(
                    `${API_URL}/auth/refresh-token`,
                    { refreshToken }
                );

                const r= response.data;

                // Update the tokens in localStorage
                localStorage.setItem('token', r.token);
                localStorage.setItem('refreshToken', r.refreshToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${r.token}`;
                return axios(originalRequest);
            } catch (error) {
                // Handle token refresh error
                console.error('Failed to refresh token:', error);
                // TODO: Redirect to login or show an error message
            }
        }

        return Promise.reject(error);
    }
);

export default api;