import {
  faCheck,
  faClock,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faMailboxFlagUp,
  faNewspaper,
  faQuestion,
  faTicketPerforated,
  faTimer,
  faTimes,
  faTrafficCone,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { TICKET_STATUS } from "../../util/constants";

function TicketStatus({ status }) {
  let color, icon, fontColor, text;
  switch (status) {
    case TICKET_STATUS.CREATED:
      color = "#85aef7";
      icon = faEnvelope;
      fontColor = "#fff";
      text = "Enviado";
      break;

    case TICKET_STATUS.QUEUED:
      color = "#ffb151";
      icon = faEnvelopeOpen;
      fontColor = "#fff";
      text = "Na fila";
      break;

    case TICKET_STATUS.IN_PROGRESS:
      color = "#8d2ccb";
      icon = faTrafficCone;
      fontColor = "#fff";
      text = "Em andamento";
      break;

    case TICKET_STATUS.WAITING_CUSTOMER:
      color = "#f54323";
      icon = faExclamationCircle;
      fontColor = "#fff";
      text = "Aguardando você";
      break;

    case TICKET_STATUS.RESOLVED:
      color = "#00c853";
      icon = faCheck;
      fontColor = "#fff";
      text = "Resolvido";
      break;

    case TICKET_STATUS.CLOSED:
      color = "#cdcdcd";
      icon = faTimes;
      fontColor = "#fff";
      text = "Fechado";
      break;
    default:
      color = "gray";
      icon = faQuestion;
      fontColor = "#000";
      text = status;
      break;
  }

  return (
    <Container color={color} fontColor={fontColor}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {text}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.color};
  color: ${(props) => props.fontColor};
  font-size: 0.7rem;
  font-weight: 600;
  gap: 0.5rem;
  // resize font size to fit container
  white-space: nowrap;
`;

export default TicketStatus;
