import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    font-size: 24px;
    padding: 10px;
    gap: 10px;
    margin: 0; /* Add this line to remove the margin */
`;

export const Links = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
`;

export const Link = styled.div`
    color: '#fff';
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
`;