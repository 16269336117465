import React from "react";

import * as S from "./styles";
import BasicContent from "../../../components/BasicContent";
import { faImage, faTicket, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import { Button, FH, Input, Label, Text } from "../../main-styles";
import WYSEditor from "../../../components/WYSEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REQ_STATUS } from "../../../util/constants";
import toast from "react-hot-toast";
import { TICKETS } from "../../../api/TICKETS";
import { UTILS } from "../../../api/UTILS";
import moment from "moment-timezone";

function Novo() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    files: [],
    status: REQ_STATUS.IDLE,
    subject: "",
    description: "",
  });

  const { files, status, subject, description } = state;

  function addFile(files) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({ ...s, files: [...s.files, ...files] }));
  }

  function removeFile(index) {
    if (status === REQ_STATUS.SENDING) return;
    setState((s) => ({
      ...s,
      files: s.files.filter((_, i) => i !== index),
    }));
  }

  function updateField(field, value) {
    setState((s) => ({ ...s, [field]: value }));
  }

  async function uploadImages() {
    // pega um signed url para upload por imagem
    const renamedFiles = files.map((file) => {
        // add a random string to the file name to avoid conflicts
        const randomString = moment().format("YYYYMMDDHHmmss")
        return new File([file], `${randomString}_${file.name}`, { type: file.type });
    });
    let promises = await UTILS.get.singed_url(renamedFiles);
    console.log("PROMISES", promises);
    // faz o upload da imagem
    let uploads = await promises.map(async (item, i) => {
      let response = await UTILS.set.upload_file(item.signed_url, renamedFiles[i]);
      console.log("RESPONSE", response);
      return item.url;
    });
    uploads = await Promise.all(uploads);
    console.log("UPLOADS", uploads);
    return uploads;
  }
  async function sendTicket() {
    if (status === REQ_STATUS.SENDING) return;
    if(!subject){
        toast.error("O campo 'Assunto' é obrigatório.");
        return;
    }
    if(!description){
        toast.error("O campo 'Descrição' é obrigatório.");
        return;
    }
    // min length for a ticket description is 40
    if(description.length < 30){
        toast.error("Por favor, adicione o máximo de informações no campo descrição, assim nossos agentes poderão responder mais rapidamente.");
        return;
    }

    setState((s) => ({ ...s, status: REQ_STATUS.SENDING }));
    
    try {
      // Send ticket
      let files = await uploadImages();
      const newid = await TICKETS.set.new(subject, description, files);
        toast.success("Ticket enviado com sucesso!");
        navigate(`/tickets/visualizar/${newid}`);
    } catch (error) {
      setState((s) => ({ ...s, status: REQ_STATUS.ERROR }));
    }
}

  return (
    <BasicContent
      title="Novo Ticket"
      icon={faTicket}
      back={() => navigate("/tickets")}
    >
      <S.Container>
        <S.Campo>
          <Label>Assunto</Label>
        </S.Campo>
        <div />
        <S.Campo>
          <Input
            value={subject}
            onChange={(e) => updateField("subject", e.target.value)}
          />
        </S.Campo>

        <Alert color="info" style={{ fontSize: "0.8em" }}>
          Tente utilizar um assunto que seja facilmente identificado por você
          posteriormente.
          <br />
          <strong>Exemplo:</strong>
          <br />
          Ao invés de utilizar somente <strong>"Erro"</strong>, tente informar
          também onde o erro ocorreu, como
          <br />
          <strong>"Erro no envio de formulário de contato"</strong>.
        </Alert>
        <S.Campo>
          <Label>Descrição</Label>
        </S.Campo>
        <div />
        <S.Campo>
          <WYSEditor
            value={description}
            onChange={(e) => updateField("description", e)}
          />
        </S.Campo>
        <Alert color="info" style={{ fontSize: "0.8em" }}>
          Descreva o ocorrido com o máximo de detalhes, incluindo data, horário,
          ações realizadas e, sempre que possível, anexe capturas de tela ou
          documentos relacionados.
          <br />
          <br />
          Tente imaginar se o atendente terá todas as informações necessárias
          para resolver o problema sem precisar de mais detalhes.
        </Alert>
        <S.Campo>
          <Label>Anexos</Label>
        </S.Campo>
        <div />
        <S.Campo>
          <label
            htmlFor="file-upload"
            style={{
              cursor: "pointer",

              border: "1px dashed #666666",
              backgroundColor: "#f9f9f9",
              borderRadius: 5,
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#666",
              gap: 5,
            }}
          >
            <FontAwesomeIcon icon={faImage} />
            Clique para anexar uma imagem
          </label>
          <input
            id="file-upload"
            type="file"
            multiple={true}
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => addFile(e.target.files)}
            disabled={status === REQ_STATUS.SENDING}
          />
          {files.length > 0 && <hr />}
          {files.map((file, index) => (
            <FH key={index} style={{ justifyContent: "space-between" }}>
              <Text>{file.name}</Text>
              <div
                style={{
                  flex: 1,
                  borderBottom: `1px dotted #ccc`,
                  height: `1rem`,
                  marginLeft: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
              />
              <Text onClick={() => removeFile(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </Text>
            </FH>
          ))}
        </S.Campo>
        <Alert color="info" style={{ fontSize: "0.8em" }}>
          Adicione imagens que possam ajudar a entender o problema.
          <br />
          <br />
          <strong>Exemplo:</strong>
          <br />
          Se você está com problemas em um formulário, envie uma captura de tela
          do erro.
        </Alert>
        <S.Campo>
          <Button onClick={sendTicket} disabled={status === REQ_STATUS.SENDING}>
            {status === REQ_STATUS.SENDING ? "Enviando..." : "Enviar Ticket"}
          </Button>
        </S.Campo>
      </S.Container>
    </BasicContent>
  );
}

export default Novo;
