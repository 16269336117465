import React from "react";

import * as S from "../styles";
import { Button } from "../../main-styles";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <S.CTA>
      Experimente sem compromisso e veja como podemos transformar o suporte ao
      cliente na sua empresa. <br />
      <Link to="/br/workspace/new">
        <Button>Comece sua avaliação gratuita de 14 dias agora mesmo!</Button>
      </Link>
    </S.CTA>
  );
}

export default CTA;
