import React from "react";

import Select, { StylesConfig } from "react-select";

const dot = (color = "transparent") => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 3,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white", fontSize: 12, width: 200 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = data.color;
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? data.color
        : isFocused
        ? "#d9d9d9"
        : undefined,
      color: isDisabled ? "#ccc" : isSelected ? "white" : data.color,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : "#d9d9d9"
          : undefined,
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot("#ccc") }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

function StatusSelect({ value, options = [], onChange, menuPlacement = "auto" }) {
    const defaultValue= options.find(option => option.value === value)
  return (
    <Select
      defaultValue={defaultValue}
      options={options}
      styles={colourStyles}
      onChange={onChange}
      placeholder="Selecione"
      menuPlacement={menuPlacement}
    />
  );
}

export default StatusSelect;
