// src/WebSocketProvider.js
import React, { createContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLocked } from "../../redux/reducers/auth";

// Cria o contexto para o WebSocket
export const WebSocketContext = createContext(null);

const SERVERURL = "http://localhost:3005";

// Cria o provedor de WebSocket
export const WebSocketProvider = ({ children }) => {
  const socketRef = useRef(null); // Use useRef para manter a instância do socket
  const [events, setEvents] = useState([]); // Estado para armazenar os eventos recebidos
  const dispatch = useDispatch();

  useEffect(() => {
    // Evita múltiplas conexões
    if (socketRef.current) {
      console.log("Socket já está conectado.");
      return;
    }

    // Função assíncrona para obter o token JWT
    const getToken = async () => {
      try {
        // Substitua pela forma correta de obter o token
        // Se você já possui o token armazenado no localStorage, utilize diretamente
        const token = localStorage.getItem("token");
        if (!token) {
          // console.error("Erro ao obter o token:", error);
          return null;
        }
        return token;
      } catch (error) {
        console.error("Erro ao obter o token:", error);
        return null;
      }
    };

    // Função para inicializar a conexão WebSocket
    const initSocket = async () => {
      const token = await getToken();
      if (!token) {
        console.error("Não foi possível obter o token JWT.");
        return;
      }

      // Inicializa a conexão com o servidor WebSocket
      const socket = io(SERVERURL, {
        auth: {
          token: token, // Passa o token JWT para autenticação
        },
        transports: ["websocket"], // Opcional: define os transportes a serem usados
      });

      socketRef.current = socket;

      // Eventos de conexão
      socket.on("connect", () => {
        // toast.success("Conectado ao servidor WebSocket.");
        console.log("Conectado ao servidor WebSocket.");
      });

      socket.on("connect_error", (err) => {
        // toast.error("Erro na conexão com o servidor WebSocket.");
        console.error("Erro na conexão:", err.message);
      });

      socket.on("disconnect", (reason) => {
        // toast.error("Desconectado do servidor WebSocket.");
        console.log("Desconectado do servidor WebSocket. Razão:", reason);
      });

      socket.on("forceDisconnect", (reason) => {
        // toast.error("Desconectado do servidor WebSocket.");
        console.log("Desconectado do servidor WebSocket. Razão:", reason);
        dispatch(setLocked({ locked: true, reason }));
        socket.disconnect();
      });

      // Escutar eventos personalizados emitidos pelo servidor
      const eventTypes = [
        "ticketRespondido",
        "ticketFechado",
        "atualizacaoTicket",
      ];

      socket.on("novoTicket", (data) => {
        console.log("[novoTicket]", data);
        toast((t) => (
          <span>
            #{data.ticketId} - {data.message}
            <button onClick={() => toast.dismiss(t.id)}>
              Ir para o ticket
            </button>
          </span>
        ));
        setEvents((prevEvents) => [
          ...prevEvents,
          { event: "novoTicket", data },
        ]);
      });

      eventTypes.forEach((eventType) => {
        socket.on(eventType, (data) => {
          toast.success(`${eventType} recebido.`);
          console.log(`[${eventType}]`, data);
          setEvents((prevEvents) => [
            ...prevEvents,
            { event: eventType, data },
          ]);
        });
      });

      // Opcional: Teste de conexão
      socket.emit("test", { message: "Teste de conexão" });
    };

    initSocket();

    // Função de limpeza para desconectar o socket quando o componente for desmontado
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []); // Executa apenas uma vez ao montar o componente

  return (
    <WebSocketContext.Provider value={{ events }}>
      {children}
    </WebSocketContext.Provider>
  );
};
