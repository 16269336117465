import React from 'react';

import * as S from './styles';

function Or({text= "ou", color = '#ececec'}) {
  return <S.Container>
    <S.Line color={color} />
    <S.Text color={color}>{text}</S.Text>
    <S.Line color={color}/>
  </S.Container>;
}

export default Or;