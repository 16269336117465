import React, { useEffect, useState } from "react";

import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink, useNavigate } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { Description, FH, Input, Link, Logo, Text } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";
import toast from "react-hot-toast";
import { USERS } from "../../../api/USERS";
import { Alert } from "reactstrap";

function RedirectToWorkspace() {
  const { appReducer } = useSelector((state) => state);
  const [workspace, setWorkspace] = useState("");
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  function gotoWorkspace() {
    console.log("gotoWorkspace");
    window.location.href = `https://${workspace}.help-desk.app/auth/sign-in`;
  }

  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
          <Description style={{ textWrap: "nowrap" }}>Qual o endereço de seu workspace?</Description>
        <FH>
          <Description style={{ textWrap: "nowrap" }}>https://</Description>
          <Input
            type="text"
            // placeholder="workspace"
            autoComplete="full-name"
            value={workspace}
            onChange={(e) => setWorkspace(e.target.value)}
            // autofocus
            autoFocus={true}
          />
          <Description style={{ textWrap: "nowrap" }}>
            .help-desk.app
          </Description>
        </FH>
        <Button onClick={gotoWorkspace}>Entrar</Button>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default RedirectToWorkspace;
