import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  Outlet,
} from "react-router-dom";
import {
  Wrapper,
  Sidebar,
  ReportArea,
  ReportSlot,
  ReportLink,
  Output,
} from "./styled.js";

const reports = [
  { id: "/csat", name: "Relatório de CSAT" },
  { id: "/tickets", name: "Desempenho de Tickets" },
  { id: "/agents/performance", name: "Performance de Agentes" },
  { id: "/groups/performance", name: "Grupos de Suporte" },
  { id: "/groups/tickets", name: "Tickets por Grupo" },
  // { id: "5", name: "Problemas Recorrentes" },
  // { id: "6", name: "SLA" },
  // { id: "7", name: "Uso por Tenant" },
  // { id: "8", name: "Feedbacks Negativos" },
];


const App = () => {

  return (
    <Wrapper>
      {/* Sidebar com a lista de relatórios */}
      <Sidebar>
        <h4>Relatórios</h4>
        <hr/>
        {reports.map((report) => (
          <ReportLink key={report.id}>
            <Link to={`/agents/reports${report.id}`}>{report.name}</Link>
          </ReportLink>
        ))}
      </Sidebar>

      {/* Área de Relatórios */}
      <ReportArea>
        <Outlet />
      </ReportArea>
    </Wrapper>
  );
};

export default App;
