import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../api/REPORTS";
import { getTicketStatusAgent } from "../../../../util/functions";
import { AGENT_TICKET_PRIORITY_ORDER } from "../../../../util/constants";
import { FH } from "../../../main-styles";

// Registrar componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TicketReport = ({ tenantId }) => {
  const [statusOverview, setStatusOverview] = useState([]);
  const [avgResolutionTime, setAvgResolutionTime] = useState(0);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    fetchTicketData();
  }, []);
  
  const fetchTicketData = async () => {
    try {
      const response = await REPORTS.get.agents.tickets(
        startDate.toISOString(),
        endDate.toISOString()
      );

      const sortedStatusOverview = response.statusOverview.sort(
        (a, b) =>
          AGENT_TICKET_PRIORITY_ORDER.indexOf(a.status) -
          AGENT_TICKET_PRIORITY_ORDER.indexOf(b.status)
      );

      setStatusOverview(sortedStatusOverview);
      setAvgResolutionTime(response.avgResolutionTime);
    } catch (error) {
      console.error("Error fetching ticket data:", error);
    }
  };

  const chartData = {
    labels: statusOverview.map(
      (item) => getTicketStatusAgent(item.status).status
    ),
    datasets: [
      {
        label: "Quantidade de Tickets",
        data: statusOverview.map((item) => item.count),
        backgroundColor: statusOverview.map(
          (item) => getTicketStatusAgent(item.status).color
        ),
      },
    ],
  };

  return (
    <Container style={{ overflow: "auto" }}>
      <h2 className="mt-4">Relatório de Desempenho de Tickets</h2>
      <Row className="my-4">
        <Col md={4}>
          <FormGroup>
            <Label for="startDate">Data Inicial</Label>
            <Input
              type="date"
              id="startDate"
              value={startDate.toISOString().split("T")[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="endDate">Data Final</Label>
            <Input
              type="date"
              id="endDate"
              value={endDate.toISOString().split("T")[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <Button color="primary" onClick={fetchTicketData}>
            Atualizar
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Bar
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false, // Permite definir uma altura personalizada
            }}
            style={{ maxHeight: "400px" }} // Limita a altura do gráfico
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <h4>Resumo</h4>
          <p>
            <strong>Tempo Médio de Resolução:</strong>{" "}
            {avgResolutionTime.toFixed(2)} horas
          </p>
          <Table striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Quantidade</th>
              </tr>
            </thead>
            <tbody>
              {statusOverview.map((item, index) => {
                console.log("item", item);
                return (
                  <tr key={index}>
                    <td>
                      <FH>
                        <div
                          style={{
                            backgroundColor: getTicketStatusAgent(item.status)
                              .color,
                            height: 10,
                            width: 10,
                            borderRadius: "50%",
                            border: "1px solid #ccc",
                          }}
                        />
                        {getTicketStatusAgent(item.status).status}
                      </FH>
                    </td>
                    <td>{item.count}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default TicketReport;
