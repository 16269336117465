import React, { useState } from "react";

import * as S from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOut, faUser } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/reducers/auth";

function UserMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { app } = useSelector((state) => state.appReducer);
  const { user } = useSelector((state) => state.authReducer);
  const [isOpen, setIsOpen] = useState(false);
  let timeoutId;

  const toggleDropdown = () => {
    setIsOpen(true);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsOpen(false);
    }, 1000); // Adjust the delay as needed
  };

  const handleLogout = () => {
    // Implement logout functionality here
    dispatch(logout());
    navigate("/auth/sign-in");
  };

  return (
    <S.Container>
      <S.UserInfo onMouseEnter={toggleDropdown} onMouseLeave={handleMouseLeave}>
        <S.Thumbnail src={user.thumbnail} alt={user.name} />
        <S.UserName  color={app?.colors?.headerText}>{user.name}</S.UserName>
      </S.UserInfo>
      {isOpen && (
        <S.DropdownMenu
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MenuItem
            icon={faUser}
            title="Perfil"
            onClick={() => navigate("/perfil")}
          />
          <MenuItem
            icon={faCog}
            title="Configurações"
            onClick={() => {
              navigate("/configuracoes");
            }}
          />
          <hr style={{ color: "#787878" }} />
          <MenuItem icon={faSignOut} title="Sair" onClick={handleLogout} />
          {/* Add more menu items here */}
        </S.DropdownMenu>
      )}
    </S.Container>
  );
}

function MenuItem({ icon, title, onClick }) {
  return (
    <S.MenuItem onClick={onClick}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {title}
    </S.MenuItem>
  );
}

export default UserMenu;
