import React from "react";
import { Col, Container, Row } from "reactstrap";
import Header from "../Header";
import { FH, Title } from "../../routes/main-styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { faAngleLeft, faPlusCircle } from "@fortawesome/pro-solid-svg-icons";

function BasicContent({ title, icon, children, add, back }) {
  const colors = useSelector((state) => state.appReducer.app.colors);
  console.log("Colors:", colors);
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col md={12}>
            <FH style={{ justifyContent: "space-between" }}>
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {back && (
                  <>
                    <div
                      onClick={back}
                      style={{
                        cursor: "pointer",

                        backgroundColor: colors?.main,
                        color: colors?.headerText,
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 25,
                        marginBottom: 10,
                        fontSize: 12,
                        opacity: 0.7,
                      }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} /> Voltar
                    </div>
                  </>
                )}
                <Title
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    color: colors?.title,
                    textTransform: "capitalize",
                  }}
                >
                  {icon && <FontAwesomeIcon icon={icon} />}
                  {title}
                </Title>
              </div>
              {add && (
                <div
                  style={{
                    marginTop: 30,
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    color: colors?.title,
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }}
                  onClick={add}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                  Adicionar
                </div>
              )}
            </FH>
            <hr style={{ color: colors?.main }} />
          </Col>
          {children}
        </Row>
      </Container>
    </div>
  );
}

export default BasicContent;
