import React, { useState } from "react";

import * as S from "../styles";

import { useDispatch, useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink, useNavigate } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { Input, Link, Logo } from "../../main-styles";
import { AUTH } from "../../../api/AUTH";
import { REQ_STATUS } from "../../../util/constants";
import { validateEmail } from "../../../util/functions";
import toast from "react-hot-toast";
import { setUser } from "../../../redux/reducers/auth";

function SignIn() {
  const navigate = useNavigate();
  const { appReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { app } = appReducer;
  const [state, setState] = useState({
    email: "",
    password: "",
    status: REQ_STATUS.IDLE,
    message: "",
  });
  const { email, password, status } = state;
  // console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;
  const tid = app?.tid;

  async function acessar() {
    console.log("acessar");
    if (!email || !password) {
      toast.error("Preencha todos os campos");
      return;
    }
    if (!validateEmail(state.email)) {
      toast.error("Email inválido");
      return;
    }
    if (state.password.length < 6) {
      toast.error("Senha inválida");
      return;
    }
    setState((s) => ({ ...s, status: REQ_STATUS.LOADING }));
    if (status !== REQ_STATUS.IDLE && status !== REQ_STATUS.ERROR) return;
    let response = await AUTH.get.authenticate(email, password, tid);
    console.log("RESPONSE:", response);
    if (response?.error) {
      toast.error(response?.error);
      setState((s) => ({
        ...s,
        status: REQ_STATUS.ERROR,
        message: response?.error,
      }));
      return;
    }
    if (response?.data?.token) {
      toast.success("Logado com sucesso", response);
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("refreshToken", response?.data?.refreshToken);
      dispatch(setUser(response?.data?.userData));
      navigate("/tickets");
    }
  }

  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Input
          type="text"
          placeholder="Email"
          autoComplete="email"
          disabled={status === REQ_STATUS.LOADING}
          value={email}
          onChange={(e) =>
            setState((s) => ({
              ...s,
              email: e.target.value,
              status: REQ_STATUS.IDLE,
            }))
          }
        />
        <Input
          type="password"
          placeholder="Senha"
          autoComplete="password"
          value={password}
          disabled={status === REQ_STATUS.LOADING}
          onChange={(e) =>
            setState((s) => ({
              ...s,
              password: e.target.value,
              status: REQ_STATUS.IDLE,
            }))
          }
        />
        {status === REQ_STATUS.ERROR && <S.Error>{state.message}</S.Error>}
        <Button onClick={acessar}>
          {status === REQ_STATUS.LOADING ? "Enviando..." : "Acessar"}
        </Button>
        <Link color={colors?.main}>Esqueci minha senha</Link>
        <Or />
        <NavLink to="/auth/sign-up" style={{ width: "100%" }}>
          <Button color style={{ width: "100%" }}>
            Crie sua conta
          </Button>
        </NavLink>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default SignIn;
