import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { REPORTS } from "../../../../../api/REPORTS";
import toast from "react-hot-toast";
import moment from "moment-timezone";

// Registrar os componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GroupPerformanceReport = ({ tenantId }) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [groupData, setGroupData] = useState([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchGroupPerformanceData();
  }, []);

  const fetchGroupPerformanceData = async () => {
    try {
      setFetching(true);
      // se a data de início for maior que a data final, não faz nada
      if (startDate > endDate) {
        toast.error("Data inicial não pode ser maior que a data final");
        return;
      }
      const response = await REPORTS.get.groups.performance(
        startDate.toISOString(),
        endDate.toISOString(),
        tenantId
      );
      console.log("Group performance data:", response);
      setGroupData(response);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching group performance data:", error);
    }
  };

  const chartData = {
    labels: groupData.map((item) => item.group_name),
    datasets: [
      {
        label: "Tickets Atendidos",
        data: groupData.map((item) => item.tickets_count),
        backgroundColor: "#007bff",
      },
    ],
  };

  return (
    <Container style={{ overflow: "auto" }}>
      <h2 className="mt-4">Relatório de Grupos de Suporte</h2>
      <Row className="my-4">
        <Col md={4}>
          <FormGroup>
            <Label for="startDate">Data Inicial</Label>
            <Input
              type="date"
              id="startDate"
              value={startDate.toISOString().split("T")[0]}
              onChange={(e) => setStartDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="endDate">Data Final</Label>
            <Input
              type="date"
              id="endDate"
              value={endDate.toISOString().split("T")[0]}
              onChange={(e) => setEndDate(new Date(e.target.value))}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="d-flex align-items-end">
          <Button color="primary" onClick={fetchGroupPerformanceData}>
            Atualizar
          </Button>
        </Col>
      </Row>
      {fetching && <p>Carregando...</p>}
      {groupData.length > 0 && (
        <>
          <div style={{ maxHeight: "400px" }}>
            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
          <Row className="mt-4">
            <Col md={12}>
              <h4>Detalhes</h4>
              <Table striped>
                <thead>
                  <tr>
                    <th>Grupo</th>
                    <th>Tickets Atendidos</th>
                    <th>Tempo Médio de Resolução</th>
                    <th>CSAT Médio</th>
                  </tr>
                </thead>
                <tbody>
                  {groupData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.group_name}</td>
                      <td>{item.tickets_count}</td>
                      <td>
                        {item.avg_resolution_time
                          ? moment
                              .duration(
                                parseFloat(item.avg_resolution_time),
                                "hours"
                              )
                              .humanize()
                          : "-"}
                      </td>
                      <td>
                        {parseFloat(item.avg_csat)?.toFixed(2) || "-"} (
                        {item.csat_count || 0} avaliações)
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default GroupPerformanceReport;
