import React, { useEffect, useState } from "react";

import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";
import Or from "../../../components/Or";

import { NavLink } from "react-router-dom";
import PoweredLink from "../../../components/PoweredLink";
import { FH, Input, Link, Logo, Text } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";
import toast from "react-hot-toast";
import { USERS } from "../../../api/USERS";
import { Alert } from "reactstrap";

function SignUp() {
  const { appReducer } = useSelector((state) => state);
  const [agreed, setAgreed] = React.useState(false);
  const [state, setState] = useState({
    status: REQ_STATUS.IDLE,
    email: "",
    emailStatus: REQ_STATUS.IDLE,
    password: "",
    confirmPassword: "",
    name: "",
    errorMessage: "",
  });
  const {
    email,
    emailStatus,
    password,
    confirmPassword,
    name,
    status,
    errorMessage,
  } = state;
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;
 
  // change window title

  async function validate() {
    console.log("Validating...");
    if (status !== REQ_STATUS.IDLE) return;
    if (!name || !email || !password || !confirmPassword) {
      console.log(`Preencha todos os campos`, state);
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: "Preencha todos os campos",
      });
      return toast.error("Preencha todos os campos");
    }
    if (name.split(" ").length < 2) {
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: "Informe seu nome completo",
      });
      return toast.error("Informe seu nome completo");
    }
    let emailValid = await checkEmail(email);
    if (!emailValid) {
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: "Email inválido",
      });
      return toast.error("Email inválido");
    }
    if (password.length < 6) {
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: "A senha deve ter no mínimo 6 caracteres",
      });
      return toast.error("A senha deve ter no mínimo 6 caracteres");
    }
    if (password !== confirmPassword) {
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: "As senhas não conferem",
      });
      return toast.error("As senhas não conferem");
    }

    if (!agreed) {
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage:
          "Você deve concordar com os termos de uso e política de privacidade",
      });
      return toast.error(
        "Você deve concordar com os termos de uso e política de privacidade"
      );
    }
    setState({ ...state, status: REQ_STATUS.LOADING });
    try {
      let response = await USERS.set.new({
        name,
        email,
        password,
      });
      setState(s=>({ ...s, status: REQ_STATUS.SUCCESS }));
    } catch (error) {
      console.log("ERROR", error);
      let message = error?.response?.data?.error;
      switch (message) {
        case 'User already exists':
          message = 'Já existe um usuário com este email';
          break;
      
        default:
          break;
      }
      toast.error(message);
      setState({
        ...state,
        status: REQ_STATUS.ERROR,
        errorMessage: message,
      });
    }
  }

  useEffect(() => {
    setState((s) => ({
      ...s,
      status: REQ_STATUS.IDLE,
      errorMessage: "",
    }));
  }, [agreed]);

  function updateField(field, value) {
    setState((s) => ({
      ...state,
      [field]: value,
      errorMessage: "",
      status: REQ_STATUS.IDLE,
    }));
  }

  async function checkEmail() {
    if (emailStatus === REQ_STATUS.LOADING) return;
    // regex
    let re = /\S+@\S+\.\S+/;
    if (!re.test(email)) {
      return false;
    }

    return true;
  }

  if(status === REQ_STATUS.SUCCESS) {
    return (
      <S.Container backgroundColor={backgroundColor}>
        <S.WhiteContainer>
          <Logo src={app?.darklogo || mainlogo} />
          <hr />
          <Text>Conta criada com sucesso!</Text>
          <Text>Verifique seu email para ativar sua conta</Text>
          <Button onClick={() => window.location.href = '/auth/sign-in'}>Acessar</Button>
        </S.WhiteContainer>
        <PoweredLink />
      </S.Container>
    );
  }

  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Input
          type="text"
          placeholder="Nome completo"
          autoComplete="full-name"
          value={name}
          onChange={(e) => updateField("name", e.target.value)}
        />
        <Input
          type="text"
          placeholder="Email"
          autoComplete="email"
          value={email}
          onChange={(e) => updateField("email", e.target.value)}
        />
        <Input
          type="password"
          placeholder="Senha"
          autoComplete="new-password"
          value={password}
          onChange={(e) => updateField("password", e.target.value)}
        />
        <Input
          type="password"
          placeholder="Confirmação de senha"
          value={confirmPassword}
          onChange={(e) => updateField("confirmPassword", e.target.value)}
        />
        <FH>
          <input
            type="checkbox"
            checked={agreed}
            onChange={() => setAgreed(!agreed)}
          />
          <Text style={{ marginLeft: 10, textAlign: "left" }}>
            Concordo com os termos de uso e política de privacidade
          </Text>
        </FH>
        {status === REQ_STATUS.ERROR && (
          <Alert color="danger" style={{ width: "100%" }}>
            {errorMessage}
          </Alert>
        )}
        <Button onClick={validate}>Criar conta</Button>
        <Link color={colors?.main}>Esqueci minha senha</Link>
        <Or />
        <Text>
          Já tem uma conta?{" "}
          <NavLink to="/auth/sign-in" style={{ color: colors?.main }}>
            clique aqui
          </NavLink>
        </Text>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default SignUp;
