import React, { useEffect } from "react";

import * as S from "./styles";
import { Text } from "../../routes/main-styles";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import UserMenu from "../UserMenu";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faTicket } from "@fortawesome/pro-solid-svg-icons";

function Header() {
  const navigate = useNavigate();
  const { app, loading } = useSelector((state) => state.appReducer);
  const { user, isAuthenticated } = useSelector((state) => state.authReducer);
  const role = user?.role || "guest";

  useEffect(() => {
    if (!loading) setup();
  }, [loading]);

  function setup() {
    console.log("Header setup");
    window.document.title = app?.title || app?.name;
    setFavicon(app?.favicon);
  }

  function setFavicon(url) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = url;
  }

  return (
    <>
      <S.Container
        bgColor={app?.colors?.main}
        textColor={app?.colors?.headerText}
        style={{
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        <Container>
          <Row>
            <Col
              md={3}
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              {app?.logo && (
                <img
                  src={app?.logo}
                  alt={app?.name}
                  style={{ height: 50, width: "auto" }}
                />
              )}
            </Col>
            <Col
              md={6}
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              {app?.title}
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                justifyContent: "flex-end",
              }}
            >
              {isAuthenticated && user ? (
                <UserMenu />
              ) : (
                <S.Links>
                  <S.Link
                    color={app?.colors?.headerText}
                    onClick={() => navigate("/auth/sign-in")}
                  >
                    Entrar
                  </S.Link>
                  <S.Link
                    color={app?.colors?.headerText}
                    href={() => navigate("/auth/sign-up")}
                  >
                    Registrar
                  </S.Link>
                </S.Links>
              )}
            </Col>
          </Row>
        </Container>
      </S.Container>
      {isAuthenticated && user && (
        <Container>
          <Row>
            <Col
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 40,
                backgroundColor: app?.colors?.background,
                padding: 10,
                paddingRight: 20,
                border: `1px solid #ececec`,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                borderTop: 0,
                // drop shadow
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              }}
            >
              <Text
                color={app?.colors?.text}
                onClick={() => navigate("/knowledge-base")}
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faBook} /> Base de Conhecimento
              </Text>
              <Text
                color={app?.colors?.text}
                onClick={() => navigate("/tickets")}
                style={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faTicket} /> Tickets
              </Text>
              {role !== "user" && <>
                <Text
                  color={app?.colors?.text}
                  onClick={() => navigate("/agents")}
                  style={{
                    fontSize: "1.2rem",
                    cursor: "pointer",
                  }}
                >
                  Painel de Atendimento
                </Text>
              </>}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Header;
