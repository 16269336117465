import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import * as S from "../../main-styles";
import Header from "../../../components/Header";
import BasicContent from "../../../components/BasicContent";
import {
  faBuilding,
  faSearch,
  faTicket,
  faTimes,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import {
  CLIENT_TICKET_PRIORITY_ORDER,
  REQ_STATUS,
  TICKET_STATUS,
  TicketTypes,
} from "../../../util/constants";
import { TICKETS } from "../../../api/TICKETS";
import BigSelect from "../../../components/BigSelect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TicketStatus from "../../../components/TicketStatus";
import { useNavigate } from "react-router-dom";
function List() {
  const navigate = useNavigate();
  const { colors } = useSelector((state) => state.appReducer.app);
  const [state, setState] = useState({
    status: REQ_STATUS.LOADING,
    data: [],
    page: 0,
    perPage: 50,
    type: TicketTypes.MINE,
    needle: "",
  });
  const { status, data, page, perPage, type, needle } = state;

  useEffect(() => {
    load();
  }, [type, page, perPage]);

  function toggleType(type) {
    setState((s) => ({ ...s, type }));
  }

  function orderTickets(tickets) {
    return tickets.sort((a, b) => {
      return (
        CLIENT_TICKET_PRIORITY_ORDER.indexOf(a.status) -
        CLIENT_TICKET_PRIORITY_ORDER.indexOf(b.status)
      );
    });
  }

  async function load() {
    setState((s) => ({ ...s, status: REQ_STATUS.RELOADING }));
    let data;
    if (type === TicketTypes.MINE) {
      data = await TICKETS.get.mine(page, perPage);
    } else {
      data = await TICKETS.get.myCompany(page, perPage);
    }
    console.log("TICKETS", data);
    data = orderTickets(data);
    setState((s) => ({ ...s, status: REQ_STATUS.IDLE, data }));
  }

  function gotoTicket(ticket) {
    console.log("GOTO", ticket);
    navigate(`/tickets/visualizar/${ticket}`);
  }

  function addTicket() {
    navigate(`/tickets/novo`);
  }

  if (status === REQ_STATUS.LOADING) {
    return (
      <BasicContent title="Tickets" icon={faTicket}>
        <Spinner />
      </BasicContent>
    );
  }

  const HASMORE = data.length === perPage;

  return (
    <BasicContent title="Tickets" icon={faTicket} add={addTicket}>
      <>
        <Row>
          {/* <Col md={12}>
            <S.Description>Exibir tickets:</S.Description>
            <BigSelect
              options={[
                {
                  text: "Meus",
                  icon: faUser,
                  value: TicketTypes.MINE,
                },
                {
                  text: "Da minha empresa",
                  icon: faBuilding,
                  value: TicketTypes.MY_COMPANY,
                },
              ]}
              selected={type}
              onSelect={toggleType}
            />
          </Col> */}
          {/* <Col md={12}>
            <S.Description>
              <FontAwesomeIcon icon={faSearch} /> Buscar
            </S.Description>
            <S.FH>
              <S.Input
                placeholder="Procure por titulo ou conteúdo"
                value={needle}
                onChange={(e) =>
                  setState((s) => ({ ...s, needle: e.target.value }))
                }
              />
              {needle !== "" && (
                <S.Button
                  className="animate__bounceIn"
                  backgroundColor={colors?.main}
                  style={{ flex: 0, paddingRight: 25, paddingLeft: 25 }}
                  onClick={() =>
                    setState((s) => ({ ...s, needle: "", page: 0 }))
                  }
                >
                  <FontAwesomeIcon icon={faTimes} />
                </S.Button>
              )}
            </S.FH>
            <hr />
          </Col> */}
          {status === REQ_STATUS.RELOADING ? (
            <Col md={12}>
              <Spinner />
            </Col>
          ) : (
            <Col md={12}>
              {data.length === 0 && (
                <S.Text>
                  <FontAwesomeIcon icon={faTicket} /> Nenhum ticket encontrado
                </S.Text>
              )}
              <Tickets>
                {data.map((ticket) => {
                  console.log(ticket);
                  const id = `${ticket.id}`;
                  const mensagens = ticket?.updates + 1;
                  const dim =
                    ticket?.status === TICKET_STATUS.CLOSED ||
                    ticket?.status === TICKET_STATUS.RESOLVED
                      ? 0.5
                      : 1;
                  return (
                    <Ticket
                      key={id}
                      color={colors?.main}
                      onClick={() => gotoTicket(ticket.id)}
                      style={{ opacity: dim }}
                    >
                      <TicketID color={colors?.main}>
                        <FontAwesomeIcon icon={faTicket} /> {id}
                        <S.Description style={{ fontSize: 10 }}>
                          {mensagens > 1
                            ? `${mensagens} mensagens`
                            : `1 mensagem`}
                        </S.Description>
                      </TicketID>
                      <TicketInfo>
                        <TicketTitle style={{ color: colors?.main }}>
                          {ticket?.subject}
                        </TicketTitle>

                        <S.Description style={{ fontSize: 12 }}>
                          {ticket.short_description}
                        </S.Description>
                      </TicketInfo>
                      <TicketStatusContainer>
                        <TicketStatus status={ticket.status} />
                      </TicketStatusContainer>
                      <TicketActions></TicketActions>
                    </Ticket>
                  );
                })}
              </Tickets>
            </Col>
          )}
        </Row>
      </>
    </BasicContent>
  );
}

const Tickets = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const Ticket = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  // align to the center
  align-items: center;
  // highlight on hover
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f05f;
  border: 1px solid #eaeaea;
  &:hover {
    background-color: ${(props) => props.color || "#cccccc"}11;
    // drop shadow
    box-shadow: 0px 0px 6px 0px #00000011;
    & > div {
      border-color: #fff;
    }
  }
`;

const TicketID = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.color};
  border-right: 1px solid #eaeaea;
  line-height: 1.1;
  text-align: right;
  padding-right: 1rem;
`;

const TicketStatusContainer = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  padding-right: 10px;

  border-right: 1px solid #eaeaea;
`;

const TicketInfo = styled.div`
  grid-area: 1 / 3 / 2 / 8;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  border-right: 1px solid #eaeaea;
  line-height: 1.2;
`;

const TicketTitle = styled.div`
  font-size: 0.99rem;
  font-weight: 500;
`;

const TicketDate = styled.div``;

const TicketActions = styled.div`
  grid-area: 1 / 8 / 2 / 9;
`;

export default List;
