import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Label, Spinner } from "reactstrap";
import styled from "styled-components";
import { Button, Description, FH, Title } from "../../routes/main-styles";
import { TICKETS } from "../../api/TICKETS";
import toast from "react-hot-toast";

function CSAT({ id, csat }) {
  const [solved, setSolved] = React.useState(null);
  const [stars, setStars] = React.useState(null);
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  useEffect(() => {
    if (csat) {
      setStars(csat.score);
      setSolved(csat.solved);
      setSent(true);
      setLoading(false);
    }
  }, [csat]);

  const STAR_TRANSLATION = {
    1: "Péssimo",
    2: "Ruim",
    3: "Regular",
    4: "Bom",
    5: "Excelente",
  };

  async function sendCSAT() {
    setSending(true);
    if (solved === null) {
      toast.error("Por favor, selecione se a solicitação foi resolvida.");
      setSending(false);
      return;
    }
    if (stars === null) {
      toast.error("Por favor, selecione a quantidade de estrelas entre 1 e 5.");
      setSending(false);
      return;
    }

    const response = await TICKETS.set.sendCSAT(id, stars, solved);
    if (response) {
      toast.success("Avaliação enviada com sucesso!");
      setSent(true);
    }
  }

  return (
    <Container>
      {loading ? (
        <Spinner />
      ) : (
        <>
              <Title>Avaliação do atendimento</Title>
          {!sent &&  (
            <>
              <Title>Avalie o atendimento</Title>
              <Description>
                Ajude-nos a melhorar! Avalie o nosso atendimento e contribua
                para uma experiência ainda melhor.
              </Description>
            </>
          )}
          <hr />
          <Label>A solicitação foi resolvida?</Label>
          <Options>
            <Option selected={solved} onClick={() => !sent && setSolved(true)}>
              Sim
            </Option>
            <Option
              selected={solved === false}
              onClick={() => !sent && setSolved(false)}
            >
              Não
            </Option>
          </Options>
          <hr />
          {sent ? <Label>
            Avaliação
          </Label>
          :<Label>Quantas estrelas você daria para o atendimento?</Label>}
          <STARContainer>
            <STAR
              disabled={sent || sending}
              active={stars && stars >= 1}
              onClick={() => !sent && setStars(1)}
            >
              <FontAwesomeIcon icon={faStar} />
            </STAR>
            <STAR
              disabled={sent || sending}
              active={stars && stars >= 2}
              onClick={() => !sent && setStars(2)}
            >
              <FontAwesomeIcon icon={faStar} />
            </STAR>
            <STAR
              disabled={sent || sending}
              active={stars && stars >= 3}
              onClick={() => !sent && setStars(3)}
            >
              <FontAwesomeIcon icon={faStar} />
            </STAR>
            <STAR
              disabled={sent || sending}
              active={stars && stars >= 4}
              onClick={() => !sent && setStars(4)}
            >
              <FontAwesomeIcon icon={faStar} />
            </STAR>
            <STAR
              disabled={sent || sending}
              active={stars && stars >= 5}
              onClick={() => !sent && setStars(5)}
            >
              <FontAwesomeIcon icon={faStar} />
            </STAR>
          </STARContainer>
          {!sent && (
            <>
              <hr />
              <Button onClick={sendCSAT}>Enviar avaliação</Button>
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Options = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap: 10px;
  padding: 0 15rem;
`;

const Option = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background 0.2s;
  background-color: ${(props) => (props.selected ? "#277aff" : "#a9a9a9")};
  color: white;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const STARContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const STAR = styled.div`
  color: ${(props) => (props.active ? "#ffbf00" : "#cccccc")};
  transition: color 0.2s;
  cursor: pointer;
  font-size: 1.5rem;
  ${props => props.disabled && "pointer-events: none;"}
  &:hover {
    color: #ffce39;
    // scale: 1.1;
    scale: 1.3;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  // align items to center
  align-items: center;
  // justify content to center
  justify-content: center;
  text-align: center;
  padding: 0.5rem 0;
`;

export default CSAT;
