import React from "react";

import * as S from "../styles";
import { Button, Description } from "../../main-styles";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <S.HeroContainer>
      <S.HeroLeft
      className="animate__animated animate__fadeInLeft"
      >
        <S.HeroTitle>Resolva chamados com rapidez e eficiência</S.HeroTitle>
        <S.HeroSubtitle>
          Organize seu suporte, melhorando e acompanhando cada atendimento.
        </S.HeroSubtitle>
        <Link to="/br/workspace/new">
          <Button style={{ maxWidth: 200 }} className="animate__animated animate__pulse animate__repeat-2">Comece agora gratuitamente</Button>
        </Link>
        <Description style={{ marginTop: 5 }}>
          Não é necessário cartão de crédito
        </Description>
      </S.HeroLeft>
      <S.HeroRight>
        <S.HeroImage
        className="animate__animated animate__fadeInRight"
        src="https://img.seidecor.com.br/fit-in/800x800/-LIG_iJcJTGJZwPKMJiZ/generated-images/generated_x426j.png" />
      </S.HeroRight>
    </S.HeroContainer>
  );
}

export default Hero;
