import {
  faCheck,
  faEnvelope,
  faEnvelopeOpen,
  faExclamationCircle,
  faTimes,
  faTrafficCone,
} from "@fortawesome/pro-solid-svg-icons";
import { TICKET_STATUS } from "./constants";
import axios from "axios";

export function validateEmail(email) {
  // valid email regex test
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function getStatusColorCustomer(status) {
  let icon, color, statusname;
  switch (status) {
    case TICKET_STATUS.CREATED:
      icon = faEnvelope;
      color = "#85aef7";
      statusname = "Enviado";
      break;

    case TICKET_STATUS.QUEUED:
      icon = faEnvelopeOpen;
      color = "#ffb151";
      statusname = "Na fila";
      break;

    case TICKET_STATUS.IN_PROGRESS:
      color = "#8d2ccb";
      icon = faTrafficCone;
      statusname = "Em andamento";
      break;

    case TICKET_STATUS.CLOSED:
      icon = faTimes;
      color = "#ccc";
      statusname = "Fechado";
      break;

    case TICKET_STATUS.RESOLVED:
      icon = faCheck;
      color = "#039f3c";
      statusname = "Resolvido";
      break;

    case TICKET_STATUS.WAITING_CUSTOMER:
      color = "#f54323";
      icon = faExclamationCircle;
      statusname = "Aguardando você";
      break;

    default:
      break;
  }

  return {
    icon,
    color,
    statusname,
  };
}

export function getTicketStatusAgent(customerStatus) {
  let status, icon, color;
  switch (customerStatus) {
    case TICKET_STATUS.CREATED:
      // icon = faEnvelope;
      // color = "#85aef7";
      // status = "Aguardando primeiro contato";
      icon = faEnvelopeOpen;
      color = "#f54323";
      status = "Aguardando primeiro contato";
      break;

    case TICKET_STATUS.QUEUED:
      icon = faEnvelopeOpen;
      color = "#ffb151";
      status = "Em fila";
      break;

    case TICKET_STATUS.IN_PROGRESS:
      icon = faTrafficCone;
      color = "#8d2ccb";
      status = "Em andamento";
      break;

    case TICKET_STATUS.CLOSED:
      icon = faTimes;
      color = "#ccc";
      status = "Fechado";
      break;

    case TICKET_STATUS.RESOLVED:
      icon = faCheck;
      color = "#039f3c";
      status = "Resolvido";
      break;

    case TICKET_STATUS.WAITING_CUSTOMER:
      color = "#ffb151";
      icon = faExclamationCircle;
      status = "Aguardando cliente";
      break;

    default:
      color = "#ccc";
      icon = faTimes;
      status = customerStatus;
      break;
  }

  return {
    status,
    color,
    icon,
  };
}

export function validaCNPJ(cnpj) {
  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  var c = String(cnpj).replace(/[^\d]/g, "");

  if (c.length !== 14) return false;

  if (/0{14}/.test(c)) return false;

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (c[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
  if (c[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

  return true;
}

export async function loadCNPJData(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");
  let payload = {
    cnpj,
    token: "e6d77531-7112-4362-b36c-d0d894893cbc",
  };
  const response = await axios.post(
    `https://gql.seidecor.com.br/checkout/cp-info`,
    payload
  );
  const data = await response.data;
  return data;
}
