import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  background-color: #eff0eb;
  // make it full screen
  max-height: 100vh;
  height: 100vh;
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Menu = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
`;

export const MenuItem = styled.div`
  color: #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
`;

export const Ticket = styled.div`
  width: 100%;
  // make it scrollable
  overflow: auto;
  // max height of 90% of the screen
  max-height: 98vh;
  padding-bottom: 50px;
`;

export const BaseColumn = styled.div`
  background-color: #f1f1f1;
  border: 1px solid #fafaf7;
  border-radius: 10px;
  // drop shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

export const TicketsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3px repeat(5, 1fr) 200px;
  grid-template-rows: 1fr;
  grid-column-gap: 010px;
  grid-row-gap: 0px;
`;

export const Separator = styled.div`
  cursor: col-resize;
  background-color: #939393;
  border-radius: 10px;
  width: 3px;
`;

export const TicketsList = styled(BaseColumn)`
  grid-column: span ${(props) => (props.maxed ? 1 : 2)};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 10px;
  position: relative;
`;

export const TicketListLeft = styled.div`
  grid-area: 1 / 1 / 2 / 3;
  border-right: 1px solid #e2e2e2;
`;

export const TicketListRight = styled.div`
  grid-area: ${(props) => (props.maxed ? `1 / 1 / 2 / 6` : `1 / 3 / 2 / 6`)};
  border-left: 1px solid #ffffffa5;
  display: flex;
  flex-direction: column;
  overflow: auto;
  // hide the scrollbar
  scrollbar-width: none;
`;

export const TitleContainer = styled.div`
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  border-bottom: ${(props) => (props.bottom ? "1px solid #e8e8e8" : "none")};
`;

export const TicketView = styled(BaseColumn)`
  /* grid-column: span 4; */
  // fill the remaining space when the other columns are occupied
  grid-column: span ${(props) => (props.maxed ? 5 : 4)};
`;

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f1f1f1;
`;

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

export const TicketDetails = styled(BaseColumn)``;
