import React from "react";

import * as S from "../styles";

import { useSelector } from "react-redux";
import mainlogo from "../../../assets/helpdesk/logo-small.png";
import Button from "../../../components/Button";

import PoweredLink from "../../../components/PoweredLink";
import { Input, Logo, Text } from "../../main-styles";
import { REQ_STATUS } from "../../../util/constants";

function ChangePassword() {
  const { appReducer } = useSelector((state) => state);
  const { app } = appReducer;
  console.log("app:", app);
  const colors = app?.colors;
  const backgroundColor = `${colors?.main}1c`;

  const [state, setState] = React.useState({
    password: "",
    confirmPassword: "",
    status: REQ_STATUS.IDLE,
  });

  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.WhiteContainer>
        <Logo src={app?.darklogo || mainlogo} />
        <Text>Vamos definir uma nova senha para sua conta.</Text>
        <Input type="password" placeholder="Nova senha" autoComplete="new-complete" />
        <Input type="password" placeholder="Confirmação de senha" />
        
        <Button>Salvar</Button>
      </S.WhiteContainer>
      <PoweredLink />
    </S.Container>
  );
}

export default ChangePassword;
